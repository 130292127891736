import type { PathsWithMethod } from 'openapi-typescript-helpers'
import type { paths, components } from '@/services/open-api/generated/cart'
import { fetchClient } from '@/services/open-api/fetchClient'

type Exclude3DS<T extends string> = T extends `${infer _Prefix}3DS` ? never : T

type BasketPostCompleteOrderPaths =
  Extract<
    keyof paths,
    `/api/v1/baskets/{basketId}/complete${string}`
  > extends `/api/v1/baskets/{basketId}/${infer T}`
    ? Exclude3DS<T>
    : never

export default {
  /**
   * Creates a new basket.
   * @param apiRoot The base URL of the API.
   * @param disposition The disposition object to pass to the API.
   * @returns The created basket object.
   */
  async CreateBasket({
    apiRoot,
    disposition
  }: {
    apiRoot: string
    disposition: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Disposition']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets', {
      body: {
        ...disposition
      }
    })
  },

  /**
   * Retrieves a basket by id.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to retrieve.
   * @returns The basket with the given id.
   */
  async GetBasketById({ apiRoot, basketId }: { apiRoot: string; basketId: string }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).GET('/api/v1/baskets/{basketId}', {
      params: {
        path: {
          basketId
        }
      }
    })
  },

  /**
   * Updates a basket.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the id of the basket to update and the updated basket object.
   * @returns The updated basket object.
   */
  async UpdateBasket({
    apiRoot,
    payload
  }: {
    apiRoot: string
    payload: {
      basketId: string
      basket: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Disposition']
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).PATCH('/api/v1/baskets/{basketId}', {
      params: {
        path: {
          basketId: payload.basketId
        }
      },
      body: {
        ...payload.basket
      }
    })
  },
  /**
   * Deletes a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to delete.
   * @returns The response of the DELETE request.
   */
  async DeleteBasket({ apiRoot, basketId }: { apiRoot: string; basketId: string }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).DELETE('/api/v1/baskets/{basketId}/basketItems', {
      params: {
        path: {
          basketId
        }
      }
    })
  },

  /**
   * Expires a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to expire.
   * @returns The response of the PATCH request.
   */
  async ExpireBasket({ apiRoot, basketId }: { apiRoot: string; basketId: string }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).PATCH('/api/v1/baskets/{basketId}/expire', {
      params: {
        path: {
          basketId
        }
      }
    })
  },

  /**
   * Adds a new basket item to a basket.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the id of the basket to add the item to and the item to add.
   * @returns The response of the POST request.
   */
  async AddBasketItem({
    apiRoot,
    payload
  }: {
    apiRoot: string
    payload: {
      basketId: string
      basketItem: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.AddBasketItem']
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/basketItems', {
      params: {
        path: {
          basketId: payload.basketId
        }
      },
      body: {
        ...payload.basketItem
      }
    })
  },

  /**
   * Deletes a basket item from a basket.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the id of the basket to delete the item from and the id of the item to delete.
   * @returns The response of the DELETE request.
   */
  async DeleteBasketItem({
    apiRoot,
    payload
  }: {
    apiRoot: string
    payload: {
      basketId: string
      basketItemId: string
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).DELETE('/api/v1/baskets/{basketId}/basketItems/{basketItemId}', {
      params: {
        path: {
          basketId: payload.basketId,
          basketItemId: payload.basketItemId
        }
      }
    })
  },

  /**
   * Updates a basket item.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the id of the basket to update the item in and the updated item object.
   * @returns The updated basket item object.
   */
  async UpdateBasketItem({
    apiRoot,
    payload
  }: {
    apiRoot: string
    payload: {
      basketId: string
      basketItem: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.BasketItem']
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).PATCH('/api/v1/baskets/{basketId}/basketItems/{basketItemId}', {
      params: {
        path: {
          basketId: payload.basketId,
          basketItemId: payload.basketItem.basketItemId!
        }
      },
      body: {
        ...payload.basketItem
      }
    })
  },

  /**
   * Updates multiple basket items.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the id of the basket to update the items in and the updated items array.
   * @returns The array of updated basket items.
   */
  async UpdateBasketItems({
    apiRoot,
    payload
  }: {
    apiRoot: string
    payload: {
      basketId: string
      basketItems: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.BasketItem'][]
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).PATCH('/api/v1/baskets/{basketId}/basketItems', {
      params: {
        path: {
          basketId: payload.basketId
        }
      },
      body: {
        ...payload.basketItems
      }
    })
  },

  /**
   * Swaps the parts of a basket item.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the id of the basket to swap the item in, the id of the item to swap, and the parts to add and remove.
   * @returns The response of the PATCH request.
   */
  async SwapBasketItemParts({
    apiRoot,
    payload
  }: {
    apiRoot: string
    payload: {
      basketId: string
      basketItemId: string
      addBasketItemParts: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.UpdateBasketItemPartsRequest']['addBasketItemParts']
      removeProductIds: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.UpdateBasketItemPartsRequest']['removeProductIds']
      updateSingle: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.UpdateBasketItemPartsRequest']['updateSingle']
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).PATCH('/api/v1/baskets/{basketId}/basketItems/{basketItemId}/swap', {
      params: {
        path: {
          basketId: payload.basketId,
          basketItemId: payload.basketItemId
        }
      },
      body: {
        addBasketItemParts: payload.addBasketItemParts,
        removeProductIds: payload.removeProductIds,
        updateSingle: payload.updateSingle
      }
    })
  },

  /**
   * Adds a new grouped basket item to a basket.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the id of the basket to add the item to, the grouped basket item to add, and whether it is a voucher or not (and the voucher code and id if it is).
   * @returns The response of the POST request.
   */
  async AddGroupedBasketItem({
    apiRoot,
    payload
  }: {
    apiRoot: string
    payload: {
      basketId: string
      groupedBasketItem: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.AddGroupedBasketItem']
      isVoucher: boolean
      voucherCode: string
      voucherId: string
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/groupedBasketItems', {
      params: {
        path: {
          basketId: payload.basketId
        }
      },
      body: {
        ...payload.groupedBasketItem,
        isVoucher: payload.isVoucher,
        ...(payload.isVoucher && {
          voucherCode: payload.voucherCode,
          voucherId: payload.voucherId
        })
      }
    })
  },

  /**
   * Updates a grouped basket item in a basket.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the id of the basket to update the item in, the grouped basket item to update, and whether it is a voucher or not (and the voucher code and id if it is).
   * @returns The response of the PATCH request.
   */
  async UpdateGroupedBasketItem({
    apiRoot,
    payload
  }: {
    apiRoot: string
    payload: {
      basketId: string
      groupedBasketItem: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.GroupedBasketItem']
      groupedBasketItemId: string
      isVoucher: boolean
      voucherCode: string
      voucherId: string
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).PATCH('/api/v1/baskets/{basketId}/groupedBasketItems/{groupedBasketItemId}', {
      params: {
        path: {
          basketId: payload.basketId,
          groupedBasketItemId: payload.groupedBasketItemId
        }
      },
      body: {
        ...payload.groupedBasketItem,
        groupedBasketItemId: payload.groupedBasketItemId,
        isVoucher: payload.isVoucher,
        ...(payload.isVoucher && {
          voucherCode: payload.voucherCode,
          voucherId: payload.voucherId
        })
      }
    })
  },

  /**
   * Deletes a grouped basket item from a basket.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the id of the basket to delete the item from and the id of the item to delete.
   * @returns The response of the DELETE request.
   */
  async DeleteGroupedBasketItem({
    apiRoot,
    payload
  }: {
    apiRoot: string
    payload: {
      basketId: string
      groupedBasketItemId: string
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).DELETE('/api/v1/baskets/{basketId}/groupedBasketItems/{groupedBasketItemId}', {
      params: {
        path: {
          basketId: payload.basketId,
          groupedBasketItemId: payload.groupedBasketItemId
        }
      }
    })
  },

  /**
   * Validates a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to validate.
   * @returns The response of the POST request.
   */
  async ValidateBasket({ apiRoot, basketId }: { apiRoot: string; basketId: string }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/validate', {
      params: {
        path: {
          basketId
        }
      }
    })
  },

  /**
   * Validates an order.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to validate.
   * @param payload The customer information to validate the order with.
   * @returns The response of the POST request.
   */
  async ValidateOrder({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.CustomerInfo']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/validateOrder', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Applies a voucher to a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to apply the voucher to.
   * @param voucherCode The code of the voucher to apply.
   * @param payload The payload object containing the optional fields to apply the voucher with.
   * @returns The response of the POST request.
   */
  async ApplyVoucher({
    apiRoot,
    basketId,
    voucherCode,
    payload
  }: {
    apiRoot: string
    basketId: string
    voucherCode: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.ApplyVoucherRequest']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/applyvoucher/{code}', {
      params: {
        path: {
          basketId,
          code: voucherCode
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Removes a voucher from a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to remove the voucher from.
   * @param groupedBasketItemId The id of the grouped basket item that contains the voucher.
   * @returns The response of the DELETE request.
   */
  async RemoveVoucher({
    apiRoot,
    basketId,
    groupedBasketItemId
  }: {
    apiRoot: string
    basketId: string
    groupedBasketItemId: string
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).DELETE('/api/v1/baskets/{basketId}/groupedBasketItems/{groupedBasketItemId}/removeVoucher', {
      params: {
        path: {
          basketId,
          groupedBasketItemId
        }
      }
    })
  },
  /**
   * Removes a cart-level voucher from a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to remove the voucher from.
   * @param voucherCode The code of the voucher to remove.
   * @returns The response of the DELETE request.
   */
  async RemoveCartVoucher({
    apiRoot,
    basketId,
    voucherCode
  }: {
    apiRoot: string
    basketId: string
    voucherCode: string
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).DELETE('/api/v1/baskets/{basketId}/removeCartVoucher/{voucherCode}', {
      params: {
        path: {
          basketId,
          voucherCode
        }
      }
    })
  },
  /**
   * Places an order for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to place the order for.
   * @param payload The payload object containing the order details.
   * @param apiEndpoint The API endpoint to use
   * @returns The response of the POST request.
   */
  async OrderBasket<T extends BasketPostCompleteOrderPaths>({
    apiRoot,
    basketId,
    payload,
    apiEndpoint
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Order']
    apiEndpoint: T
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST(`/api/v1/baskets/${basketId}/${apiEndpoint}` as PathsWithMethod<paths, 'post'>, {
      // @ts-expect-error
      body: {
        ...payload
      }
    })
  },

  /**
   * Initialises Visa Checkout for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to initialise Visa Checkout for.
   * @param payload The payload object containing the details of the Visa Checkout request.
   * @returns The response of the POST request.
   */
  async InitialiseVco({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Cybersource.VisaCheckout.Request.InitialiseVCORequest']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/initialiseVCO', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Initialises Flex for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to initialise Flex for.
   * @returns The response of the POST request.
   */
  async InitialiseFlex({ apiRoot, basketId }: { apiRoot: string; basketId: string }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/initialiseFlex', {
      parseAs: 'text',
      params: {
        path: {
          basketId
        }
      }
    })
  },

  /**
   * Initialises Flex API for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to initialise Flex API for.
   * @returns The response of the POST request.
   */
  async InitialiseFlexApi({ apiRoot, basketId }: { apiRoot: string; basketId: string }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/initialiseFlexApi', {
      params: {
        path: {
          basketId
        }
      }
    })
  },

  /**
   * Deletes a Flex API token for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to delete the token for.
   * @param token The Flex API token to delete.
   * @returns The response of the DELETE request.
   */
  async DeleteFlexApiToken({
    apiRoot,
    basketId,
    token
  }: {
    apiRoot: string
    basketId: string
    token: string
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).DELETE('/api/v1/baskets/{basketId}/token/{flexToken}', {
      params: {
        path: {
          basketId,
          flexToken: token
        }
      }
    })
  },

  /**
   * Verifies a Flex payment for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to verify the payment for.
   * @param payload The request payload for the verification.
   * @returns The response of the POST request.
   */
  async VerifyFlex({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Cybersource.Flex.Request.VerifyFlexRequest']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/verifyFlex', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Initialises Apple Pay for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to initialise Apple Pay for.
   * @param payload The request payload for the initialisation.
   * @returns The response of the POST request.
   */
  async InitialiseApplePay({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Cybersource.ApplePay.InitialiseApplePayRequest']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/initialiseApplePay', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Initialises Zip Pay for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to initialise Zip Pay for.
   * @param payload The request payload for the initialisation.
   * @returns The response of the POST request.
   */
  async InitialiseZipPay({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Order']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/initialiseZipPay', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Completes an Apple Pay transaction for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to complete the transaction for.
   * @param payload The request payload for the completion.
   * @returns The response of the POST request.
   */
  async CompleteApplePay({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Order']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/completeApplePay', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Updates the payment information for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to update the payment information for.
   * @param payload The request payload for the update.
   * @returns The response of the PATCH request.
   */
  async UpdatePayment({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.PaymentInfo']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).PATCH('/api/v1/baskets/{basketId}/paymentInfo', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Reorders a previous order.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to add the previous order to.
   * @param customerId The id of the customer to reorder the order for.
   * @param disposition The disposition of the order.
   * @param options The request options.
   * @returns The response of the POST request.
   */
  async AddPreviousOrder({
    apiRoot,
    basketId,
    customerId,
    disposition,
    options
  }: {
    apiRoot: string
    basketId: string
    customerId: string
    disposition: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Disposition']
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot,
      headers: options.headers
    }).POST('/api/v1/baskets/{basketId}/customers/{customerId}/reorder', {
      params: {
        path: {
          basketId,
          customerId
        }
      },
      body: {
        ...disposition
      }
    })
  },

  /**
   * Copies a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to copy.
   * @param disposition The disposition of the order.
   * @returns The response of the POST request.
   */
  async CopyBasket({
    apiRoot,
    basketId,
    disposition
  }: {
    apiRoot: string
    basketId: string
    disposition: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.DispositionCustomerDetail']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/copyBasket', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...disposition
      }
    })
  },

  /**
   * Initialises PayPal for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to initialise PayPal for.
   * @returns The response of the POST request.
   */
  async InitialisePayPal({ apiRoot, basketId }: { apiRoot: string; basketId: string }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/InitialisePayPal', {
      params: {
        path: {
          basketId
        }
      }
    })
  },

  /**
   * Retrieves a customer token for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to retrieve the customer token for.
   * @param payload The order object to pass to the API.
   * @returns The customer token.
   */
  async GetCustomerToken({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Order']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/customerToken', {
      parseAs: 'text',
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Enrolls a customer in payer authentication for a basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to enroll the customer in payer authentication for.
   * @param payload The order object to pass to the API.
   * @param referenceID The reference ID of the payer authentication request.
   * @returns The response of the POST request.
   */
  async PayerAuthEnroll({
    apiRoot,
    basketId,
    payload,
    referenceID
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Order']
    referenceID: string
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/payerAuth/{referenceID}', {
      params: {
        path: {
          basketId,
          referenceID
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Notifies the server of an order error that occurred on the frontend.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to notify the server about.
   * @param payload The payload object containing the error message and other details.
   * @param referenceID The reference ID of the payer authentication request, if applicable.
   * @returns The response of the POST request.
   */
  async NotifyOrderError({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.FrontEndPlaceOrderError']
    referenceID: string
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/baskets/{basketId}/notifyOrderError', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Retrieves the Afterpay options for a given basket.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to retrieve the options for.
   * @returns The Afterpay options for the basket.
   */
  async GetAfterpayOptions({ apiRoot, basketId }: { apiRoot: string; basketId: string }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).GET('/api/v1/baskets/{basketId}/apOptions', {
      params: {
        path: {
          basketId
        }
      }
    })
  },

  /**
   * Notifies Afterpay of the outcome of an order.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to notify Afterpay about.
   * @param payload The details of the order to notify Afterpay about.
   * @returns The response of the PATCH request.
   */
  async NotifyAfterpay({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Cybersource.Afterpay.AfterpayPaymentDetails']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).PATCH('/api/v1/baskets/{basketId}/apNotify', {
      params: {
        path: {
          basketId
        }
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Notifies ZipPay of the outcome of an order.
   * @param apiRoot The base URL of the API.
   * @param basketId The id of the basket to notify ZipPay about.
   * @returns The response of the PATCH request.
   */
  async NotifyZipPay({ apiRoot, basketId }: { apiRoot: string; basketId: string }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).PATCH('/api/v1/baskets/{basketId}/zipNotify', {
      params: {
        path: {
          basketId
        }
      }
    })
  },

  /**
   * Initialises the Click to Pay process for a given basket.
   *
   * @param {Object} params - The parameters for the request.
   * @param {string} params.apiRoot - The root URL of the API.
   * @param {string} params.basketId - The ID of the basket to initialise the Click to Pay process for.
   * @param {components['schemas']['Serverless.Cart.Api.Models.Basket.Response.CustomerInfo']} params.payload - The payload containing customer details.
   * @returns A promise that resolves with the response from the API.
   */
  async InitialiseClickToPay({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Response.CustomerInfo']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST(
      '/api/v1/baskets/{basketId}/initialiseClickToPay',
      {
        parseAs: 'text',
        params: {
          path: {
            basketId
          }
        },
        body: {
          ...payload
        }
      }
    )
  },

  /**
   * Completes the Click to Pay process for a given basket.
   *
   * @param {Object} params - The parameters for the request.
   * @param {string} params.apiRoot - The root URL of the API.
   * @param {string} params.basketId - The ID of the basket to complete the Click to Pay process for.
   * @param {components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Order']} params.payload - The payload containing order details.
   * @returns A promise that resolves with the response from the API.
   */
  async CompleteClickToPay({
    apiRoot,
    basketId,
    payload
  }: {
    apiRoot: string
    basketId: string
    payload: components['schemas']['Serverless.Cart.Api.Models.Basket.Request.Order']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST(
      '/api/v1/baskets/{basketId}/completeClickToPay',
      {
        params: {
          path: {
            basketId
          }
        },
        body: {
          ...payload
        }
      }
    )
  }
}
