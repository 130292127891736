import { defineStore } from 'pinia'
import  type { components  } from '@/services/open-api/generated/discover'

export type DiscoverState = {
  configuration: components['schemas']['DiscoveryResponse']
}

export const useDiscoverStore = defineStore('discover', {
  state: (): DiscoverState => ({
    configuration: {
      uris: {},
      flags: {},
      settings: {}
    }
  }),

  getters: {
    flags: (state) => state.configuration.flags,
    settings: (state) => state.configuration.settings,
    uris: (state) => state.configuration.uris
  },

  actions: {
    setConfiguration (payload: components['schemas']['DiscoveryResponse']) {
      this.configuration = payload
    }
  }
})
