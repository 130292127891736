import  type { paths  } from '@/services/open-api/generated/product'
import { fetchClient } from '@/services/open-api/fetchClient'
import dayjs from 'dayjs'

export default {
  /**
   * Retrieves the categories of a store.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the parameters.
   * @param payload.storeCode The store code to retrieve categories for.
   * @param payload.fulfilmentDateTime The date and time of the order.
   * @param payload.fulfilmentType The type of order (delivery or pickup).
   * @returns A promise resolving to the categories of the store.
   */
  async GetCategories ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      storeCode?: string
      fulfilmentDateTime?: string
      fulfilmentType?: string
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/product/categories', {
      params: {
        query: {
          ...payload,
          fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime!).format('YYYY-MM-DDTHH:mm')
        }
      }
    })
  },

  /**
   * Retrieves the products of a store, filtered by category and other parameters.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the parameters.
   * @param payload.primaryCategory The primary category to filter by.
   * @param payload.secondaryCategory The secondary category to filter by.
   * @param payload.storeCode The store code to retrieve products for.
   * @param payload.fulfilmentDateTime The date and time of the order.
   * @param payload.fulfilmentType The type of order (delivery or pickup).
   * @param payload.includeIngredientDetails Whether to include ingredients in the response.
   * @param payload.cached Whether to use the cached version of the products.
   * @returns A promise resolving to the products of the store, filtered by the given parameters.
   */
  async GetProducts ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      primaryCategory?: string
      secondaryCategory?: string
      storeCode?: string
      fulfilmentDateTime?: string
      fulfilmentType?: string
      includeIngredientDetails?: boolean
      cached?: boolean
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/product/products', {
      params: {
        query: {
          ...payload,
          fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime!).format('YYYY-MM-DDTHH:mm')
        }
      }
    })
  },

  /**
   * Retrieves a product by its slug.
   * @param apiRoot The base URL of the API.
   * @param slug The slug of the product to retrieve.
   * @param payload The payload object containing the parameters.
   * @param payload.storeCode The store code to retrieve the product for.
   * @param payload.fulfilmentDateTime The date and time of the order.
   * @param payload.fulfilmentType The type of order (delivery or pickup).
   * @returns A promise resolving to the product with the given slug.
   */
  async GetProductBySlug ({ apiRoot, slug, payload } : {
    apiRoot: string
    slug: string
    payload: {
      storeCode?: string
      fulfilmentDateTime?: string
      fulfilmentType?: string
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/product/products/slug/{slug}', {
      params: {
        path: {
          slug
        },
        query: {
          ...payload,
          fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime!).format('YYYY-MM-DDTHH:mm')
        }
      }
    })
  },

  /**
   * Retrieves a product by its id.
   * @param apiRoot The base URL of the API.
   * @param productId The id of the product to retrieve.
   * @param payload The payload object containing the parameters.
   * @param payload.storeCode The store code to retrieve the product for.
   * @param payload.fulfilmentDateTime The date and time of the order.
   * @param payload.fulfilmentType The type of order (delivery or pickup).
   * @returns A promise resolving to the product with the given id.
   */
  async GetProductsByProductId ({ apiRoot, productId, payload } : {
    apiRoot: string
    productId: string
    payload: {
      storeCode?: string
      fulfilmentDateTime?: string
      fulfilmentType?: string
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/product/products/{productId}', {
      params: {
        path: {
          productId
        },
        query: {
          ...payload,
          fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime!).format('YYYY-MM-DDTHH:mm')
        }
      }
    })
  },

  /**
   * Retrieves a list of products by their ids.
   * @param apiRoot The base URL of the API.
   * @param productIds The ids of the products to retrieve.
   * @param payload The payload object containing the parameters.
   * @param payload.storeCode The store code to retrieve the product for.
   * @param payload.fulfilmentDateTime The date and time of the order.
   * @param payload.fulfilmentType The type of order (delivery or pickup).
   * @returns A promise resolving to the products with the given ids.
   */
  async GetProductsByProductIds ({ apiRoot, payload, productIds } : {
    apiRoot: string
    productIds: string[]
    payload: {
      storeCode?: string
      fulfilmentDateTime?: string
      fulfilmentType?: string
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/product/productIds/List', {
      params: {
        query: {
          ...payload,
          fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime!).format('YYYY-MM-DDTHH:mm'),
          productId: productIds
        }
      }
    })
  },

  /**
   * Retrieves the surcharges for a given product id.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the parameters.
   * @param payload.productId The id of the product to retrieve surcharges for.
   * @param payload.storeId The id of the store to retrieve surcharges for.
   * @param payload.fulfilmentDateTime The date and time of the order.
   * @param payload.fulfilmentType The type of order (delivery or pickup).
   * @returns A promise resolving to the surcharges for the given product id.
   */
  async GetSurchargesByProductId ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      productId?: string
      storeId?: number
      fulfilmentDateTime?: string
      fulfilmentType?: string
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/product/surcharges', {
      params: {
        query: {
          ...payload,
          fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime!).format('YYYY-MM-DDTHH:mm'),
        }
      }
    })
  },

  /**
   * Retrieves a meal by its slug.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the parameters.
   * @param payload.slug The slug of the meal to retrieve.
   * @param payload.storeCode The store code to retrieve the meal for.
   * @param payload.fulfilmentDateTime The date and time of the order.
   * @param payload.fulfilmentType The type of order (delivery or pickup).
   * @param payload.cached Whether to use the cached version of the meal.
   * @returns A promise resolving to the meal.
   */
  async GetMealBySlug ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      slug: string
      storeCode?: string
      fulfilmentDateTime?: string
      fulfilmentType?: string
      cached?: boolean
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/product/meals/slug/{slug}/short', {
      params: {
        path: {
          slug: payload.slug
        },
        query: {
          ...payload,
          fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime!).format('YYYY-MM-DDTHH:mm'),
        }
      }
    })
  },

  /**
   * Retrieves a meal by its id.
   * @param apiRoot The base URL of the API.
   * @param productId The id of the meal to retrieve.
   * @param payload The payload object containing the parameters.
   * @param payload.storeCode The store code to retrieve the meal for.
   * @param payload.fulfilmentDateTime The date and time of the order.
   * @param payload.fulfilmentType The type of order (delivery or pickup).
   * @returns A promise resolving to the meal with the given id.
   */
  async GetMealById ({ apiRoot, productId, payload } : {
    apiRoot: string
    productId: string
    payload: {
      storeCode?: string
      fulfilmentDateTime?: string
      fulfilmentType?: string
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/product/meals/{productId}', {
      params: {
        path: {
          productId
        },
        query: {
          ...payload,
          fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).utcOffset(payload.fulfilmentDateTime!).format('YYYY-MM-DDTHH:mm'),
        }
      }
    })
  },

  /**
   * Retrieves the navigation items for the given fulfilment date and time.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the parameters.
   * @param payload.fulfilmentDateTime The date and time of the order.
   * @returns A promise resolving to the navigation items.
   */
  async GetNavigationItems ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      fulfilmentDateTime?: string
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/navigation', {
      params: {
        query: {
          fulfilmentDateTime: dayjs(payload.fulfilmentDateTime).format('YYYY-MM-DDTHH:mm')
        }
      }
    })
  }
}