import  type { paths, components  } from '@/services/open-api/generated/customer'
import { fetchClient } from '@/services/open-api/fetchClient'

export default {
  /**
   * Registers a customer.
   * @param apiRoot The base URL of the API.
   * @param data The payload object containing the register data.
   * @returns The response of the POST request.
   */
  async Register ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.Register']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/register', {
      body: {
        ...data
      }
    })
  },

  /**
   * Logs in a customer.
   * @param apiRoot The base URL of the API.
   * @param data The payload object containing the login data.
   * @returns The response of the POST request.
   */
  async Login ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.Provider.OAuthPasswordRequest']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/token', {
      body: {
        ...data
      }
    })
  },

  /**
   * Logs in a customer using an OAuth provider.
   * @param apiRoot The base URL of the API.
   * @param data The payload object containing the OAuth provider request data.
   * @returns The response of the POST request.
   */
  async OAuthLogin ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.Provider.OAuthProviderRequest']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/oauth', {
      body: {
        ...data
      }
    })
  },

  /**
   * Requests a Zip token for a customer.
   * @param apiRoot The base URL of the API.
   * @param data The payload object containing the OAuth provider request data.
   * @returns The response of the POST request.
   */
  async ZipToken ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.Provider.OAuthProviderRequest']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/ziptoken', {
      body: {
        ...data
      }
    })
  },

  /**
   * Refreshes a customer token.
   * @param apiRoot The base URL of the API.
   * @param data The payload object containing the OAuth provider request data.
   * @returns The response of the POST request.
   */
  async RefreshToken ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.Provider.OAuthRefreshTokenRequest']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/token/refresh', {
      body: {
        ...data
      }
    })
  },

  /**
   * Revokes a customer token.
   * @param apiRoot The base URL of the API.
   * @param data The payload object containing the OAuth provider request data.
   * @returns The response of the POST request.
   */
  async RevokeToken ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.Provider.OAuthRefreshTokenRequest']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/token/revoke', {
      body: {
        ...data
      }
    })
  },

  /**
   * Verifies a given recaptcha token
   * @param apiRoot The base URL of the API
   * @param data The recaptcha token to verify
   * @returns The verification result of the given recaptcha token
   */
  async VerifyRecaptchaToken ({ apiRoot, data } : {
    apiRoot: string
    data: string
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/recaptcha', {
      body: data
    })
  },

  /**
   * Requests a password reset token.
   * @param apiRoot The base URL of the API.
   * @param data The payload object containing the request data.
   * @returns The response of the POST request.
   */
  async RequestPasswordResetToken ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.ForgottenPassword']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/password/forgotten', {
      body: {
        ...data
      }
    })
  },

  /**
   * Verifies a given password reset token
   * @param apiRoot The base URL of the API
   * @param data The payload object containing the request data
   * @returns The verification result of the given password reset token
   */
  async VerifyPasswordResetToken ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.ValidateToken']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/password/validatetoken', {
      body: {
        ...data
      }
    })
  },

  /**
   * Resets a customers password.
   * @param apiRoot The base URL of the API.
   * @param data The payload object containing the request data.
   * @returns The response of the POST request.
   */
  async ResetPassword ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.ResetPassword']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/password/resetpassword', {
      body: {
        ...data
      }
    })
  },

  /**
   * Retrieves a customer profile by the given id.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to retrieve the profile for.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The customer profile with the given id.
   */
  async GetProfile ({ apiRoot, id, options } : {
    apiRoot: string
    id: string,
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).GET('/api/v1/customer/{id}', {
      params: {
        path: { id }
      }
    })
  },

  /**
   * Updates a customer profile.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to update the profile for.
   * @param data The payload object containing the profile data to update.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the PATCH request.
   */
  async UpdateProfile ({ apiRoot, id, data, options } : {
    apiRoot: string
    id: string,
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.Update'],
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).PATCH('/api/v1/customer/{id}', {
      params: {
        path: { id }
      },
      body: {
        ...data
      }
    })
  },

  /**
   * Deletes a customer profile.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to delete the profile for.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the DELETE request.
   */
  async DeleteProfile ({ apiRoot, id, options } : {
    apiRoot: string,
    id: string,
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).DELETE('/api/v1/customer/{id}', {
      params: {
        path: { id }
      }
    })
  },

  /**
   * Updates the password of a customer.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to update the password for.
   * @param data The new password data.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the POST request.
   */
  async UpdatePassword ({ apiRoot, id, data, options } : {
    apiRoot: string
    id: string,
    options: {
      headers: {
        Authorization: string
      }
    }
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.ChangePassword']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).POST('/api/v1/customer/{id}/password', {
      params: {
        path: { id }
      },
      body: {
        ...data
      }
    })
  },

  /**
   * Saves a credit card to a customer profile.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to save the card for.
   * @param data The payload object containing the credit card data to save.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the POST request.
   */
  async SaveCreditCard ({ apiRoot, id, data, options } : {
    apiRoot: string
    id: string,
    options: {
      headers: {
        Authorization: string
      }
    }
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.AddCard']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).POST('/api/v1/customer/{id}/card', {
      params: {
        path: { id }
      },
      body: {
        ...data
      }
    })
  },

  /**
   * Removes a credit card from a customer profile.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to remove the card from.
   * @param cardId The id of the card to remove.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the DELETE request.
   */
  async RemoveCreditCard ({ apiRoot, id, cardId, options } : {
    apiRoot: string
    id: string,
    cardId: string
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).DELETE('/api/v1/customer/{id}/card/{cardId}', {
      params: {
        path: {
          id,
          cardId
        }
      },
    })
  },

  /**
   * Updates the status of a credit card from a customer profile.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to update the card of.
   * @param cardId The id of the card to update.
   * @param data The new status of the card.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the PATCH request.
   */
  async UpdateCardStatus ({ apiRoot, id, cardId, data, options } : {
    apiRoot: string
    id: string,
    cardId: string,
    data: components['schemas']['Serverless.Customer.Api.CardStatus'],
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).PATCH('/api/v1/customer/{id}/card/{cardId}/status', {
      params: {
        path: {
          id,
          cardId
        }
      },
      body: {
        ...data
      }
    })
  },

  /**
   * Updates the default status of a credit card from a customer profile.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to update the card of.
   * @param cardId The id of the card to update.
   * @param data The new status of the card.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the PATCH request.
   */
  async UpdateDefaultCard ({ apiRoot, id, cardId, data, options } : {
    apiRoot: string
    id: string,
    cardId: string,
    data: components['schemas']['Serverless.Customer.Api.CardDefault'],
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).PATCH('/api/v1/customer/{id}/card/{cardId}/default', {
      params: {
        path: {
          id,
          cardId
        }
      },
      body: {
        ...data
      }
    })
  },

  /**
   * Retrieves a credit card from a customer profile.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to retrieve the card of.
   * @param cardId The id of the card to retrieve.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the GET request.
   */
  async GetCreditCard ({ apiRoot, id, cardId, options } : {
    apiRoot: string
    id: string,
    cardId: string,
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).GET('/api/v1/customer/{id}/card/{cardId}', {
      params: {
        path: {
          id,
          cardId
        }
      }
    })
  },

  /**
   * Updates the service type for a customer.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to update the service type for.
   * @param data The data to send in the request body.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the POST request.
   */
  async UpdateServiceType ({ apiRoot, id, data, options } : {
    apiRoot: string
    id: string,
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.SaveDisposition'],
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).POST('/api/v1/customer/{id}/disposition', {
      params: {
        path: {
          id
        }
      },
      body: {
        ...data
      }
    })
  },

  /**
   * Updates the pickup store for a customer.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to update the pickup store for.
   * @param data The data to send in the request body.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the POST request.
   */
  async UpdatePickupStore ({ apiRoot, id, data, options } : {
    apiRoot: string
    id: string,
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.AddPickupStore'],
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).POST('/api/v1/customer/{id}/store', {
      params: {
        path: {
          id
        }
      },
      body: {
        ...data
      }
    })
  },

  /**
   * Removes the pickup store for a customer.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to remove the pickup store for.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the DELETE request.
   */
  async RemovePickupStore ({ apiRoot, id, options } : {
    apiRoot: string
    id: string,
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).DELETE('/api/v1/customer/{id}/store', {
      params: {
        path: {
          id
        }
      }
    })
  },

  /**
   * Updates the delivery address for a customer.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to update the delivery address for.
   * @param data The data to send in the request body.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the POST request.
   */
  async UpdateDeliveryAddress ({ apiRoot, id, data, options } : {
    apiRoot: string
    id: string,
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.AddDeliveryAddress'],
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).POST('/api/v1/customer/{id}/address', {
      params: {
        path: {
          id
        }
      },
      body: {
        ...data
      }
    })
  },

  /**
   * Removes the delivery address for a customer.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to remove the delivery address for.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the DELETE request.
   */
  async RemoveDeliveryAddress ({ apiRoot, id, options } : {
    apiRoot: string
    id: string,
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).DELETE('/api/v1/customer/{id}/address', {
      params: {
        path: {
          id
        }
      }
    })
  },

  /**
   * Sends a verification email for a customer.
   *
   * @param apiRoot The base URL of the API.
   * @param data The data to send in the request body.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the POST request.
   */
  async SendVerifyEmail ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.SendVerifyEmail'],
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/customer/verifyemail/send', {
      body: {
        ...data
      }
    })
  },

  /**
   * Verifies a customer email address.
   *
   * @param apiRoot The base URL of the API.
   * @param data The data to send in the request body.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the PATCH request.
   */
  async VerifyEmail ({ apiRoot, data } : {
    apiRoot: string
    data: components['schemas']['Serverless.Customer.Api.Models.JsonViews.ValidateToken'],
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).PATCH('/api/v1/customer/verifyemail/update', {
      body: {
        ...data
      }
    })
  },

  /**
   * Retrieves the vouchers for a customer.
   *
   * @param apiRoot The base URL of the API.
   * @param id The id of the customer to retrieve the vouchers for.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns The response of the GET request.
   */
  async GetVouchers ({ apiRoot, id, options } : {
    apiRoot: string
    id: string,
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).GET('/api/v1/customer/{id}/vouchers', {
      params: {
        path: {
          id
        }
      }
    })
  }
}
