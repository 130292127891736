import { isMobileWeb } from '@/services/utility/user-agent.utility.js'
import {
  isAndroidApp,
  isCapacitorNativePlatform,
  isOldAndroidApp,
  isOldiOSApp,
  isiOsApp
} from '@/services/utility/capacitor.utility'

export function checkbrowserOrDevice (schedule) {
  const platformCheckFns = {
    'android-app': isOldAndroidApp,
    'android-app-v2': isAndroidApp,
    'ios-app': isOldiOSApp,
    'ios-app-v2': isiOsApp,
    'mobile-web': () => !isCapacitorNativePlatform() && isMobileWeb(),
    'desktop-web': () => !isCapacitorNativePlatform() && !isMobileWeb()
  }

  return !schedule.browsers_devices?.length || schedule.browsers_devices.some(platform => platformCheckFns[platform]?.())
}
