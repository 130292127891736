import  type { paths, components  } from '@/services/open-api/generated/store'
import { fetchClient } from '@/services/open-api/fetchClient'
import { buildFormData } from '@/services/utility/object.utility'
import dayjs from 'dayjs'

export default {
  /**
   * Fetches store information based on the provided store code.
   * @param apiRoot - The root URL of the API.
   * @param code - The code of the store to retrieve.
   * @returns  A promise that resolves to the store information.
   */
  async GetStoreByContactCode ({ apiRoot, code }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/store/contact/{code}', {
      params: {
        path: {
          code
        }
      }
    })
  },


  /**
   * Retrieves a store by its code.
   * @param apiRoot The base URL of the API.
   * @param code The code of the store to retrieve.
   * @returns A promise resolving to the response of the API.
   */
  async GetStoreByCode ({ apiRoot, code } : {
    apiRoot: string
    code: number
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/store/{code}', {
      params: {
        path: {
          code
        }
      }
    })
  },

  /**
   * Retrieves a store by its name.
   * @param apiRoot The base URL of the API.
   * @param name The name of the store to retrieve.
   * @returns A promise resolving to the response of the API.
   */
  async GetStoreByName ({ apiRoot, name } : {
    apiRoot: string
    name: string
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/store/name', {
      params: {
        query: {
          name
        }
      }
    })
  },

  /**
   * Retrieves a list of stores by their postcode.
   * @param apiRoot The base URL of the API.
   * @param payload The request payload.
   * @param payload.searchQuery The postcode to search for.
   * @param payload.includeTradingException Whether to include trading exceptions in the results.
   * @returns A promise resolving to the response of the API.
   */
  async GetStoresByPostcode ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      searchQuery: string
      includeTradingException: boolean
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/store/schedule/postcode', {
      params: {
        query: {
          postcode: payload.searchQuery,
          includeTradingException: payload.includeTradingException
        }
      }
    })
  },

  /**
   * Retrieves a list of stores by their suburb name.
   * @param apiRoot The base URL of the API.
   * @param payload The request payload.
   * @param payload.searchQuery The search query to filter the results.
   * @param payload.includeTradingException Whether to include trading exceptions in the results.
   * @param payload.suburb The suburb to search for.
   * @param payload.postcode The postcode to search for.
   * @param payload.state The state to search for.
   * @returns A promise resolving to the response of the API.
   */
  async GetStoresBySuburbName ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      searchQuery: string
      includeTradingException: boolean
      suburb: string
      postcode: string
      state: string
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/store/schedule/locate', {
      params: {
        query: {
          search: payload.searchQuery || '',
          includeTradingException: payload.includeTradingException,
          suburb: payload.suburb || '',
          postcode: payload.postcode || '',
          state: payload.state || ''
        }
      }
    })
  },

  /**
   * Retrieves a list of stores by their geolocation.
   * @param apiRoot The base URL of the API.
   * @param payload The request payload.
   * @param payload.longitude The longitude of the location to search around.
   * @param payload.latitude The latitude of the location to search around.
   * @param payload.isPickup Whether to only show stores that support pickup.
   * @param payload.radius The radius (in meters) of the search area.
   * @returns A promise resolving to the response of the API.
   */
  async GetStoresByGeolocation ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      longitude: number
      latitude: number
      isPickup: boolean
      radius: number
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/store/geolocation', {
      params: {
        query: {
          Longitude: payload.longitude,
          Latitude: payload.latitude,
          IsPickup: payload.isPickup,
          ...(payload.radius && { Radius: payload.radius })
        }
      }
    })
  },

  /**
   * Retrieves a list of addresses by their search query.
   * @param apiRoot The base URL of the API.
   * @param query The search query to filter the results.
   * @returns A promise resolving to the response of the API.
   */
  async GetAddressesByQuery ({ apiRoot, query } : {
    apiRoot: string
    query: string
  }) {
    const payload = `<?xml version="1.0" encoding="UTF-8"?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ond="http://www.qas.com/OnDemand-2011-03"><soapenv:Header><ond:QAQueryHeader /></soapenv:Header><soapenv:Body><ond:QASearch><ond:Country>AUG</ond:Country><ond:Engine Flatten="true" Intensity="Close" PromptSet="Default" Threshold="25" Timeout="10000">Intuitive</ond:Engine><ond:Layout></ond:Layout><ond:Search>${query}</ond:Search></ond:QASearch></soapenv:Body></soapenv:Envelope>`

    const response = await fetch(apiRoot, {
      method: 'POST',
      headers: {
        'Content-type': 'text/xml',
        'Auth-Token': '36dc4498-bc31-4e15-ae19-cbbbc3ad5d47', // @TODO Update with environment specific token from discover api once present
        SoapAction: 'http://www.qas.com/OnDemand-2011-03/DoSearch'
      },
      body: payload
    })

    return {
      data: await response.text()
    }
  },

  /**
   * Retrieves a single address by its moniker.
   * @param apiRoot The base URL of the API.
   * @param moniker The moniker of the address to retrieve.
   * @returns A promise resolving to the response of the API.
   */
  async GetAddressByMoniker ({ apiRoot, moniker } : {
    apiRoot: string
    moniker: string
  }) {
    const payload = `<?xml version="1.0" encoding="UTF-8"?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ond="http://www.qas.com/OnDemand-2011-03"><soapenv:Header><ond:QAQueryHeader xmlns="http://www.qas.com/OnDemand-2011-03"></ond:QAQueryHeader></soapenv:Header><soapenv:Body><QAGetAddress Threshold="50" xmlns="http://www.qas.com/OnDemand-2011-03"><Moniker>${moniker}</Moniker><Layout>APAC PizzaHut AUG</Layout></QAGetAddress></soapenv:Body></soapenv:Envelope>`

    const response = await fetch(apiRoot, {
      method: 'POST',
      headers: {
        'Content-type': 'text/xml',
        'Auth-Token': '36dc4498-bc31-4e15-ae19-cbbbc3ad5d47', // @TODO Update with environment specific token from discover api once present
        SoapAction: 'http://www.qas.com/OnDemand-2011-03/DoGetAddress'
      },
      body: payload
    })

    return {
      data: await response.text()
    }
  },

  /**
   * Submits a contact form.
   * @param apiRoot The base URL of the API.
   * @param payload The form data to submit.
   * @returns A promise resolving to the response of the API.
   */
  async SubmitContactForm ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      'FeedbackType.Primary': string
      'FeedbackType.Secondary': string
      'FeedbackType.Tertiary'?: string
      FirstName?: string
      LastName?: string
      Mobile?: string
      Address?: string
      Email: string
      Category?: string
      Breakdown?: string
      'Store.State'?: string
      'Store.Name'?: string
      'Store.Code'?: number
      'Store.Id'?: number
      VisitDate?: string
      Comment?: string
      Files?: string[]
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).POST('/api/v1/storeform/contact', {
      bodySerializer(body) {
        return buildFormData(body)
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Submits a franchisee form.
   * @param apiRoot The base URL of the API.
   * @param payload The form data to submit.
   * @returns A promise resolving to the response of the API.
   */
  async SubmitFranchiseeForm ({ apiRoot, payload } : {
    apiRoot: string
    payload: components['schemas']['FranchiseModel']
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).POST('/api/v1/storeform/franchise', {
      body: {
        ...payload
      }
    })
  },

  /**
   * Submits a careers form.
   * @param apiRoot The base URL of the API.
   * @param payload The form data to submit.
   * @returns A promise resolving to the response of the API.
   */
  async SubmitCareersForm ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      FirstName: string
      LastName: string
      StoreCode: number
      Eligibility: string
      Vaccination?: string
      EmploymentType: string
      Role: string[]
      Experience?: boolean
      PreviousFood?: boolean
      PreviousCustomer?: boolean
      RSA?: boolean
      Licence?: boolean
      ContactNo: string
      DateOfBirth: string
      Email: string
      Gender?: string
      Address: string
      City: string
      GeoPreference?: string
      EmergencyContact?: string
      StartWorkDate?: string
      Commitments?: string
      MedicalConditions?: string
      Resume?: string
      LinkedIn?: string
      EmployeeReferal?: string
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).POST('/api/v1/storeform/careers', {
      bodySerializer(body) {
        return buildFormData(body)
      },
      body: {
        ...payload
      }
    })
  },

  /**
   * Retrieves a list of all stores.
   * @param {Object} options
   * @param {string} options.apiRoot - The base URL of the API
   * @param {boolean} options.includeComingSoon - Whether to include stores that are coming soon
   * @returns A promise resolving to the response of the API.
   */
  async GetStoreList ({ apiRoot, includeComingSoon } : {
    apiRoot: string
    includeComingSoon: boolean
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/store', {
      params: {
        query: {
          includeComingSoon
        }
      }
    })
  },

  /**
   * Validates a geolocation against a store's geolocation.
   * @param apiRoot The base URL of the API.
   * @param payload The request payload.
   * @param payload.storeId The ID of the store to validate against.
   * @param payload.longitude The longitude of the location to validate.
   * @param payload.latitude The latitude of the location to validate.
   * @param payload.orderDueAt The due date and time of the order.
   * @param payload.isPickup Whether to validate for pickup or delivery.
   * @returns A promise resolving to the response of the API.
   */
  async GetValidateGeolocation ({ apiRoot, payload } : {
    apiRoot: string
    payload: {
      storeId: number
      longitude: number
      latitude: number
      orderDueAt: string
      isPickup: boolean
    }
  }) {
    return await fetchClient<paths>({baseUrl: apiRoot}).GET('/api/v1/store/{code}/validate/geolocation', {
      params: {
        path: {
          code: payload.storeId
        },
        query: {
          Longitude: payload.longitude,
          Latitude: payload.latitude,
          OrderDueAt: dayjs(payload.orderDueAt).utcOffset(payload.orderDueAt).format('YYYY-MM-DDTHH:mm'),
          IsPickup: payload.isPickup
        }
      }
    })
  }
}