import { pick } from '@/services/utility/object.utility'
import { CacheController } from '@/controllers/cache.controller'
import { config } from '@/config/global.config'
import { UPSELL } from '@/config/constants'
import { logContentCardImpressions } from '@/services/utility/braze.utility'

import { useCartStore } from '@/stores/cart'
import { useProductStore } from '@/stores/product'
import { useMarketingStore } from '@/stores/marketing'
import { useCacheStore } from '@/stores/cache'
import { useContentCardsStore } from '@/stores/content-cards'
import { useUpsellStore } from '@/stores/upsell'

export const UpsellController = {
  tryIngredientUpsell: async function ({ ingredientUpsell, product, basketItem, callback }) {
    if (!basketItem) {
      // get the last item added to basket
      basketItem = this.getLastBasketItem()
    }

    if (!ingredientUpsell) {
      const upsells = await this.getUpsells({ product })
      ingredientUpsell = upsells?.find(upsell => upsell.designCode?.toLowerCase() === 'ingredientupsell')
    }

    if (!basketItem || !product || !ingredientUpsell) {
      return false
    }

    if (!ingredientUpsell.removeProducts) {
      // Avoid mutation
      ingredientUpsell = { ...ingredientUpsell, removeProducts: [] }
    }

    const currBase = basketItem.basketItemParts.find(part => part.size)

    // Remove Upsell Ingredients which are already in the basket item
    let ingredientUpsellProducts = ingredientUpsell.products.filter(p => !(basketItem.basketItemParts.find(part => part.productId === p.productId)))
    // Remove Base Upsell Ingredients if they are not applicable to the basket item
    ingredientUpsellProducts = ingredientUpsellProducts.reduce((upsellProducts, upsellProduct) => {
      const productBase = product.bases.find(base => base.productId === upsellProduct.productId)
      if (productBase) {
        const productSize = product.sizes.find(size => size.sizeId.toString() === currBase.size.toString() && size.bases.find(base => base === productBase.productId))
        if (!productSize) {
          return upsellProducts
        }
        // Remove current base
        if (currBase) {
          ingredientUpsell.removeProducts = [...ingredientUpsell.removeProducts, currBase.productId]
        }
      }
      return [...upsellProducts, upsellProduct]
    }, [])

    if (ingredientUpsellProducts.length) {
      const modifiedUpsell = {
        ...ingredientUpsell,
        products: ingredientUpsellProducts
      }

      this.displayUpsellPopup('ingredient', modifiedUpsell, basketItem, callback)
      return modifiedUpsell
    }
    return false
  },

  tryUpsell: async function ({ product }) {
    if (useMarketingStore.upsellsLocked) {
      return false
    }
    const upsells = await this.getUpsells({ product })
    if (upsells?.length) {
      const posterUpsell = upsells.find(upsell => upsell.designCode === UPSELL.DESIGN_CODE.POSTER)
      if (posterUpsell) {
        return this.displayUpsellPopup('poster', posterUpsell)
      }
      const productUpsell = upsells.find(upsell => upsell.designCode === UPSELL.DESIGN_CODE.PRODUCT)
      if (productUpsell) {
        return this.displayUpsellPopup('interactive', productUpsell)
      }
    }
  },

  getUpsells: async function ({ product }) {
    const trigger = product.productType === 'Meals' ? 'adddealtocart' : 'addtocart'
    const getMatchedUpsells = upsells => upsells
      ? upsells.filter(upsell => upsell.requiredProducts?.includes(product.productId) && upsell.triggers?.includes(trigger))
      : []
    try {
      if (CacheController.isCacheExpired(useCacheStore().upsells.expires_at) || !getMatchedUpsells(useCacheStore().upsells?.cache).length) {
        await useMarketingStore().getUpsells({trigger, productId: product.productId })
      }
      return getMatchedUpsells(useCacheStore().upsells?.cache)
    } catch (err) {
      console.error(err)
      return []
    }
  },

  updateUpsellProducts: async function () {
    const upsells = useCacheStore().upsells?.cache
    if (!upsells) return

    const productIds = upsells.flatMap(u => u.products).map(p => p.productId)
    if (!productIds) return

    const products = await useProductStore().getProductsByProductIds({
      productIds,
      storeCode: useCartStore().storeInfo.storeId,
      fulfilmentDateTime: useCartStore().basket.orderDueAt,
      fulfilmentType: useCartStore().basketServiceType
    })

    const updatedUpsells = upsells.map(upsell => {
      const updatedProducts = upsell.products.map(up => {
        const product = products.find(p => p.productId === up.productId)
        return product ? pick(Object.keys(up), product) : up
      })
      return { ...upsell, products: updatedProducts }
    })

    await useCacheStore().setCache({
      expires_at: CacheController.getTimeInXMinutes(config.CACHE_DURATION_IN_MINUTES),
      cache: {
        upsells: updatedUpsells
      },
      cacheType: 'upsells'
    })

    return upsells
  },

  displayIngredientUpsell: async function ({ addProductIds = [], removeProductIds = [], buttonText, imageName, imageUrl, mobileImage, description, inAppMessage }) {
    if (!addProductIds) {
      throw new Error('addProductIds is required')
    }

    // get product details of what to upsell
    const productsToUpsell = await useProductStore().getProductsByProductIds({
      productIds: addProductIds,
      storeCode: useCartStore().storeInfo.storeId,
      fulfilmentDateTime: useCartStore().basket.orderDueAt,
      fulfilmentType: useCartStore().basketServiceType
    })

    if (!productsToUpsell?.length) {
      throw new Error('No products listed in addProductIds were found')
    }

    // manaully create upsell construct
    const ingredientUpsell = {
      buttonText,
      designCode: UPSELL.DESIGN_CODE.INGREDIENT,
      id: -1,
      imageName,
      imageUrl,
      mobileImage,
      popupDescription: description,
      popupName: 'BrazePopup',
      products: productsToUpsell,
      redirectLink: null,
      removeProducts: removeProductIds,
      inAppMessage
    }

    // get basketItem
    const basketItem = this.getLastBasketItem()

    // get details of that product
    const product = await useProductStore().getProduct({
      slug: basketItem.slug,
      storeCode: useCartStore().storeInfo.storeId,
      fulfilmentDateTime: useCartStore().basket.orderDueAt,
      fulfilmentType: useCartStore().basketServiceType
    })

    if (!product) {
      throw new Error('Could not retrieve the basket item product')
    }

    return this.tryIngredientUpsell({ ingredientUpsell, product, basketItem })
  },

  displayPosterUpsell: async function ({ imageName, imageUrl, mobileImage, description, redirectLink, inAppMessage }) {
    this.displayUpsellPopup('poster', {
      designCode: UPSELL.DESIGN_CODE.POSTER,
      id: -1,
      imageName,
      imageUrl,
      mobileImage,
      popupDescription: description,
      popupName: 'BrazePopup',
      products: [],
      redirectLink,
      inAppMessage
    })
  },

  displayProductUpsell: async function ({ productIds = [], description, inAppMessage }) {
    if (!productIds) {
      throw new Error('productIds is required')
    }

    const productstoUpsell = await useProductStore().getProductsByProductIds({
      productIds,
      storeCode: useCartStore().storeInfo.storeId,
      fulfilmentDateTime: useCartStore().basket.orderDueAt,
      fulfilmentType: useCartStore().basketServiceType
    })

    if (!productstoUpsell?.length) {
      throw new Error('No products listed in productIds were found')
    }

    this.displayUpsellPopup('interactive', {
      designCode: UPSELL.DESIGN_CODE.PRODUCT,
      id: -1,
      popupDescription: description,
      popupName: 'BrazePopup',
      products: productstoUpsell,
      inAppMessage
    })
  },

  displayUpsellPopup: function (type, upsell, basketItem, callback) {
    return useMarketingStore().toggleUpsellPopup({
      type,
      upsell,
      basketItem,
      callback
    })
  },

  hideUpsellPopup: function () {
    useMarketingStore().toggleUpsellPopup({
      active: false,
      upsell: {}
    })
  },

  incrementRedemptions: function () {
    useMarketingStore().incrementUpsellRedemptions()
  },

  incrementInteractions: function () {
    useMarketingStore().incrementUpsellInteractions()
  },

  incrementDismissals: function () {
    useMarketingStore().incrementUpsellDismissals()
  },

  getLastBasketItem: function () {
    // get the last item added to basket
    const event = window.dataLayer.reverse().find(x => x.event === 'addToCart')
    const eventProduct = event?.ecommerce?.add?.products?.[0]

    if (!eventProduct) {
      throw new Error('Could not retrieve the last event product')
    }

    const basketItems = JSON.parse(JSON.stringify(useCartStore().basketItems))
    const basketItem = basketItems.reverse().find(bi => (bi.totalPrice / bi.quantity) === eventProduct.price && bi.productId === eventProduct.id)

    if (!basketItem) {
      throw new Error('Could not retrieve the last basket item')
    }

    return basketItem
  },
  getUpsellProducts: async function () {
    const basket = useCartStore().basket

    // Get the upsell products from content cards
    // And remove products that are already on the cart
    const upsellProducts = useContentCardsStore().getUpsellProducts.filter(productId =>
      !basket.basketItems.map(item => item.productId).includes(productId.product_id) && !basket.groupedBasketItems.map(item => item.productId).includes(productId.product_id)
    )

      // remove duplicate items from upsellProducts
      const getUpsellProductsIds = upsellProducts.map(item => item.product_id)
      const uniqueUpsellProductsIds = [...new Set(getUpsellProductsIds)]
      const getUpsellProducts = uniqueUpsellProductsIds.map(id => upsellProducts.find(item => item.product_id === id))

      const defaultCards = upsellProducts.filter(item => item?.default === 'true').reduce((unique, o) => {
        if (!unique.some(obj => obj?.value?.name === o?.title)) {
          unique.push({
            value: {
              default: o?.default === 'true',
              id: o?.id,
              sequence: getSequenceInt(o?.sequence),
              url: o?.url,
              upsell_type: o?.upsell_type,
              name: o?.title
            }
          })
        }
        return unique
      }, [])
    const storeProducts = await useProductStore().getProductsByProductIds({
      productIds: getUpsellProducts.filter(item => item?.product_id !== undefined).map(item => item?.product_id),
      storeCode: basket.storeInfo.storeId,
      fulfilmentDateTime: basket.orderDueAt,
      fulfilmentType: basket.serviceType
    })

    const products = getUpsellProducts
      .filter(item => item?.product_id !== undefined)
      .map((item) => {
      if(item?.product_id) {
        // Find the matching product from storeProducts
        const product = storeProducts.find(storeProduct => storeProduct.name === item.title)
        if (product && !product.isOutOfStock) {
          logContentCardImpressions(product.id);
          return {
            value: {
              ...product,
              upsell_type: item.upsell_type,
              id: product.id,
              sequence: getSequenceInt(item?.sequence)
            }
          }
        }
      }
    })

    // merge default cards and flattened products
    const productsAndDefaultCards = [...products.flat(), ...defaultCards]
    useUpsellStore().setCartUpsells(
      // Categorise the products
      Object.entries(
        productsAndDefaultCards
          .filter(product => product?.value?.default ||  product?.value)
          .map(product => product.value)
          .reduce((products, { upsell_type, ...product }) => {
            if (!products[upsell_type]) {
              products[upsell_type] = []
            }

            products[upsell_type].push({ ...product })

            return products
          }, {})
      ).map(([category, products]) => ({ category, products: products.sort((a, b) => a?.sequence - b?.sequence) }))
      .filter(upsell => !(upsell.products.length === 1 && upsell.products[0].default))
    )
  },

  getCheckoutUpsellProducts: async function () {
    const basket = useCartStore().basket
    const contentCardsStore = useContentCardsStore()

    // Get the product IDs from the content cards store, but only if they are not already in the cart
    const productIds = contentCardsStore.getCheckoutUpsellProducts
      .filter(productId => !basket.basketItems.map(item => item.productId).includes(productId.product_id) &&
        !basket.groupedBasketItems.map(item => item.productId).includes(productId.product_id))
      .map(item => item.product_id)

    // Remove duplicate product IDs so we don't get duplicates in the final array
    const uniqueProductIds = [...new Set(productIds)]

    // Get the products for the unique product IDs
    const products = await useProductStore().getProductsByProductIds({
      productIds: uniqueProductIds,
      storeCode: basket.storeInfo.storeId,
      fulfilmentDateTime: basket.orderDueAt,
      fulfilmentType: basket.serviceType
    })

    // Filter out the products that are out of stock
    const inStockProducts = products.filter(product => !product.isOutOfStock)

    // Loop through the in stock products and log a content card impression for each one
    // Also, add the product to the products array
    return inStockProducts.map((product) => {
      const item = contentCardsStore.getCheckoutUpsellProducts.find(i => i.product_id === product.productId)
      logContentCardImpressions(item.id)
      return {
        ...product,
        id: item.id
      }
    })
  },
}

function getSequenceInt (sequence) {
  if (sequence === '-1') {
    return Infinity
  } else if (sequence?.length > 0) {
    return parseInt(sequence)
  } return 0
}
