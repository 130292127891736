<template>
  <router-view />
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { useIdle } from '@vueuse/core'
import { SessionController } from '@/controllers/session.controller'
import { UiController } from '@/controllers/ui.controller'
import { ContentController } from '@/controllers/content.controller'
import { UpsellController } from '@/controllers/upsell.controller'
import { TrackingController } from '@/controllers/tracking.controller'
import { config } from '@/config/global.config'
import debounce from 'lodash/debounce'
import Bus from '@/services/events/event.bus'
import {
  initBraze,
  changeUser,
  setCustomUserAttribute,
  incrementCustomUserAttribute
} from '@/services/utility/braze.utility'
import { VaultController } from '@/controllers/vault.controller'
import dayjs from 'dayjs'
import {
  isCapacitorNativePlatform
} from '@/services/utility/capacitor.utility'
import { Toast } from '@capacitor/toast'
import { dictionary } from '@/config/dictionary.config.ts'
import { toSnakeCase, toLowerCase } from '@/services/utility/string.utility'
import { isTruthyUUID } from '@/services/utility/uuid.utility'
import { getURLSearchQueryParams } from '@/services/utility/query-parameters.utility.js'
import { getAttributions } from '@/services/helpers/attributions.helper'
import { metaDefaults } from '@/services/helpers/meta.helper'
import { useHead } from '@unhead/vue'

import { useCacheStore } from '@/stores/cache'
import { useCartStore } from '@/stores/cart'
import { useCustomerStore } from '@/stores/customer'
import { useDiscoverStore } from '@/stores/discover'
import { useOrderStore } from '@/stores/order'
import { useTrackingStore } from '@/stores/tracking'
import { useUserStore } from '@/stores/user'
import { useAttributionStore } from '@/stores/attribution.js'
import { useTheme } from './composables/useTheme'

const cacheStore = useCacheStore()
const cartStore = useCartStore()
const customerStore = useCustomerStore()
const discoverStore = useDiscoverStore()
const orderStore = useOrderStore()
const trackingStore = useTrackingStore()
const userStore = useUserStore()

useHead({
  ...metaDefaults
})

const props = defineProps({
  configuration: {
    type: Object,
    default: null
  }
})

useTheme()

onMounted(async () => {
  checkIRPIDParam()
  getUTMQueryParams()
  useAttributionStore().setAttributions(await getAttributions())
  customerStore.checkIfAuthIsExpired()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', () => trackResize())
  window.removeEventListener('beforeunload', setIRPIDSession)

  if (isCapacitorNativePlatform()) {
    window.removeEventListener('offline', checkInternetConnection)
    window.removeEventListener('online', checkInternetConnection)
  }
})

const route = useRoute()

const idleInterval = ref(undefined)
const { idle } = useIdle(config.TIME_TO_IDLE)
const orderIsPolling = computed(() => orderStore.isPolling)
watch(idle, (idleValue) => {
  if (idleValue) {
    if (orderIsPolling.value) {
      orderStore.setPolling()
    }
    expireUserSession()
  } else {

    // Don't expire user session if active before expiry
    resetExpireUserSession()

    const validBasket = computed(() => cartStore.validBasket)
    if (validBasket.value) {
      validateSession()
    }
  }
})

const checkInternetConnection = (status) => {
  Toast.show({
    text: `${status.type === 'online' ? dictionary.CAPACITOR.NETWORK.ONLINE.text : dictionary.CAPACITOR.NETWORK.OFFLINE.text}`,
    duration: 'long'
  })
}

if (isCapacitorNativePlatform()) {
  window.addEventListener('offline', checkInternetConnection)
  window.addEventListener('online', checkInternetConnection)
}

UiController.showSpinner({
  loading: false
})

discoverStore.setConfiguration(props.configuration)
cacheStore.setLastCacheCheck(dayjs().format())
trackingStore.resetTracking()

ContentController.getSpinner()
ContentController.getDictionary()
ContentController.getFilterIcons()

// Get splash screen if not localised, otherwise this is handled in Basket Controller
if (!cartStore.validBasket) {
  ContentController.getSplashScreen()
}

if (!window.upsellManager) {
  window.upsellManager = UpsellController
}
if (!window.PHA_DL) {
  window.PHA_DL = window.PHA_DL || undefined
}
if (!window.dataLayer) {
  window.dataLayer = window.dataLayer || []
}
if (!window.tf) {
  import('@typeform/embed').then((typeform) => {
    window.tf = typeform
  })
}
if (!window.TrackingController) {
  window.TrackingController = TrackingController
}

// Braze
const settings = computed(() => discoverStore.settings)
const uris = computed(() => discoverStore.uris)
initBraze(settings.value.API_BRAZE_KEY, uris.value.URL_BRAZE_SDK)

// Identity Vault
VaultController.initialiseVault()

window.addEventListener('resize', () => trackResize())
// set irpid param value to irpid sessionStorage before reload

const setIRPIDSession = () => {
  if (window.irpidparameter) sessionStorage.setItem('irpid', window.irpidparameter)
}
window.addEventListener('beforeunload', setIRPIDSession)

const validateSession = () => {
  // Wait for route to resolve
  if (route.path === '/' && !route.name) {
    setTimeout(validateSession, 250)
    return
  }

  if (
    !config.SESSION_VALIDATION_BLACKLIST.NAME.some((name) => route.name === name) &&
    !config.SESSION_VALIDATION_BLACKLIST.PATH.some((path) => path.test(route.path))
  ) {
    UiController.showSpinner({ loading: true })
    SessionController.validateSession()
      .finally(() => UiController.showSpinner({ loading: false }))
  }
}
// Don't validate session when successful 3DSSuccess or when processing an order as a new basket will be created
// and validating here will overwrite the new basket
if (cartStore.validBasket) {
  validateSession()
}

const checkIRPIDParam = () => {
  // Check if url has irpid query params
  const urlParams = new URLSearchParams(window.location.search)
  const irpidParam = urlParams.get('irpid')
  const irpidSession = sessionStorage.getItem('irpid')
  if (irpidParam) {
    window.irpidparameter = irpidParam
  } else if (irpidSession) {
    // Check if irpid in sessionStore is available after reload
    // re-assign irpidSession value to window.irpidparameter then remove irpid session key after reload
    window.irpidparameter = irpidSession
    sessionStorage.removeItem('irpid')
  } else {
    window.irpidparameter = ''
  }
}

const setBrazeUTMAttributes = (query) => {
  if (query.utm_source) {
    setCustomUserAttribute('last_source', query.utm_source)
    incrementCustomUserAttribute(`source_(${toLowerCase(query.utm_source)})_count`, 1)
  }
  if (query.utm_medium) {
    setCustomUserAttribute('last_medium', query.utm_medium)
    incrementCustomUserAttribute(`medium_${toSnakeCase(query.utm_medium)}_count`, 1)
  }
  if (query.utm_campaign) {
    setCustomUserAttribute('last_campaign', query.utm_campaign)
  }
  if (query.utm_term) {
    setCustomUserAttribute('last_term', query.utm_term)
  }
  if (query.utm_content) {
    setCustomUserAttribute('last_content', query.utm_content)
  }
}

const getUTMQueryParams = () => {
  const query = getURLSearchQueryParams()

  const userId = computed(() => userStore.userId)
  if (isTruthyUUID(userId.value) && Object.keys(query).length > 0) {
    changeUser(userId.value)
    setBrazeUTMAttributes(query)
  }
}

const trackResize = () =>
  debounce(() => {
    Bus.$emit('resize')
  }, 250)

/* Auth Idle Expiry */
const expireUserSession = async () => {
  // Expire user session if auth and not "remembered" and not logged in via a provider
  // and not using biometrics
  const unlockMode = await VaultController.getUnlockMode()
  const isAuth = computed(() => customerStore.isAuth)
  const refreshToken = computed(() => customerStore.refreshToken)
  const isProviderAuth = computed(() => customerStore.isProviderAuth)
  if (
    isAuth.value &&
    !refreshToken.value &&
    !isProviderAuth.value &&
    unlockMode === 'SecureStorage'
  ) {
    // Expire User Session after x mins of inactivity
    const expireMs = config.IDLE_SESSION_EXPIRY - config.TIME_TO_IDLE
    idleInterval.value = setInterval(() => {
      customerStore.logout({ userAction: 'idleExpiry' })
      resetExpireUserSession()
    }, expireMs)
  }
}

const resetExpireUserSession = () => {
  if (idleInterval.value) {
    clearInterval(idleInterval.value)
    idleInterval.value = undefined
  }
}
/* Auth Idle Expiry END */
</script>
<style lang="scss">
  @import '@/assets/styles/main.scss';
</style>
