export const config = {
  NEW_SESSION_STORE_ID: 0,
  ERROR_CODE: {
    EXPIRED_BASKET: 'BasketHasExpired',
    ORDER_FAILED: 'OrderFailed',
    INVALID_DATETIME: 'InvalidOrderDateTime',
    INVALID_BASKET: 'BasketNotValid',
    ZIP_OUT_OF_STOCK: 'ZipPayOutOfStock'
  },
  MAX_PIZZA_INGREDIENT_COUNT: 10,
  MAX_ALCOHOL_UNIT_COUNT: 6,
  MOBILE_NUMBER_PREFIX: '+614',
  LOCATION_SEARCH_QUERY_MIN_CHARACTERS: 3,
  TIME_TO_IDLE: 600000, // 10 minutes
  IDLE_SESSION_EXPIRY: 900000, // 15 minutes,
  CACHE_DURATION_IN_MINUTES: 5,
  SESSION_VALIDATION_BLACKLIST: {
    NAME: [
      'blog',
      'blog-post',
      'campaign-form',
      'careers',
      'cms-content',
      'complete-afterpay',
      'contact',
      'forgot-password',
      'franchise',
      'franchise-thanks',
      'opt-out',
      'order',
      'process-order',
      'reset-password',
      'unsubscribe',
      'unsubscribe-email',
      'verify-email',
      'complete-afterpay',
      'blog',
      'blog-post',
      'unsubscribe',
      'unsubscribe-email',
      'contact'
    ],
    PATH: [
      /offers\/.*$/,
      /order\/.*$/,
      /oauth\/.*$/
    ]
  },
  SERVICE_WORKER_UPDATE_BLACKLIST: [
    'order',
    'process-order',
    'checkout',
    'login',
    'register'
  ],
  DISPOSITION_OVERWRITE_BLACKLIST: [
    'checkout'
  ],
  CONTENT_SPLASH_SCREEN_WHITELIST: [
    'landing',
    'meal',
    'offers',
    'product-redirect',
    'menu-category'
  ],
  SPLASH: {
    MAX_DIMISSALS: 2,
    EXPIRY: 10 // in mins
  },
  LOCALISATION_POPOVER_WHITELIST: [
    'landing',
    'offers',
    'menu-category'
  ]
}
