import { Capacitor } from '@capacitor/core'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { isWebView, isAndroid, isIOS } from '@/services/utility/user-agent.utility.js'

export const isCapacitorNativePlatform = (): boolean => {
  return Capacitor.isNativePlatform()
}

export const getCapacitorPlatform = (): string => {
  return Capacitor.getPlatform()
}

export const isOldAndroidApp = (): boolean => {
  return (isWebView() && isAndroid() && Capacitor.getPlatform() === 'web')
}

export const isOldiOSApp = (): boolean => {
  return (isWebView() && isIOS() && Capacitor.getPlatform() === 'web')
}

export const isAndroidApp = (): boolean => {
  return Capacitor.getPlatform() === 'android'
}

export const isiOsApp = (): boolean => {
  return Capacitor.getPlatform() === 'ios'
}

export const triggerHaptics = (): void => {
  if (Capacitor.isNativePlatform() || 'vibrate' in navigator) {
    Haptics.impact({ style: ImpactStyle.Heavy })
  }
}
