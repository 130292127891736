import { createRouter, createWebHistory } from 'vue-router'
import { redirectGuard } from '@/services/helpers/redirect.guard.helper'
import { TrackingController } from '@/controllers/tracking.controller'
import { UiController } from '@/controllers/ui.controller'
import { validateMinimumSpend, validateMaximumAlcohol, validateAlcoholWithFood, validatePaymentTypeOption } from '@/services/helpers/cart.helper.js'
import { checkLocaliseQueryParams, removeQueryParam } from '@/services/helpers/router.helper.js'
import { toCurrency } from '@/services/utility/currency.utility.js'
import { useUiStore } from '@/stores/ui'
import { useCustomerStore } from '@/stores/customer'
import { useSurveyStore } from '@/stores/survey'
import { useLocalisationStore } from '@/stores/localisation'
import { useSidebarStore } from '@/stores/sidebar'
import { useCacheStore } from '@/stores/cache'
import { useUserStore } from '@/stores/user'
import { useFeatureFlagsStore } from '@/stores/feature-flags'
import { setProperties } from './services/helpers/fullstory.helper'

import { paymentComponents as PAYMENT, EXPRESS_PAYMENT_METHODS } from '@/services/helpers/payment-methods.helper'
import { PROVIDER } from '@/config/provider.config.js'

const routes = [
  // 301 Redirects
  /**
   * https://pizzahutaustralia.atlassian.net/browse/ERS-5434
   * 301 Redirects
   * @TODO Should remove these in late 2019 or early 2020 when Google has had time to indes new URL's.
   */
  {
    path: '/pizza-hut-:storeName',
    redirect: '/stores/pizza-hut-:storeName'
  },
  {
    path: '/content/:slug',
    redirect: '/:slug'
  },
  {
    path: '/menu/:category/:subcategory/:slug',
    redirect: '/product/:category/:slug'
  },
  {
    path: '/schnitzza',
    redirect: '/menu/schnitzza'
  },
  {
    name: 'callcenter-mode',
    path: '/callcentermode/:agentName',
    component: () => import('@/views/landing/Landing.vue'),
    async beforeEnter (to, from, next) {
      await useCustomerStore().setCallCenter({
        mode: true,
        agentName: to.params.agentName
      })



      next('/')
    }
  },
  {
    name: 'qr',
    path: '/:baseRoute*/qr/:storeName/:serviceType/:orderDueAt/:productId?',
    component: () => import('@/views/shared/Locate.vue')
  },
  {
    name: 'qr-basket',
    path: '/:baseRoute*/qr/:storeName/:serviceType/:orderDueAt/basket/:basketId?',
    component: () => import('@/views/shared/Locate.vue')
  },
  {
    name: 'locate',
    path: '/:baseRoute*/locate/:storeName?/:serviceType?/:orderDueAt?',
    component: () => import('@/views/shared/Locate.vue')
  },
  {
    name: 'survey',
    path: '/:baseRoute*/survey/:surveyId',
    async beforeEnter (to, from, next) {
      const redirectUrl = `/${to.params.baseRoute?.join('/')}`
      try {
        const orderId = redirectUrl.match(/order\/(.*)$/)?.[1]

        // Get Survey
        const survey = await useSurveyStore().getSurvey( {
          surveyId: to.params.surveyId,
          orderId,
          focused: true
        })

        const questionIds = useSurveyStore().questions?.map(q => q.questionId)
        const currentQuestionId = useSurveyStore().currentQuestionId
        const currentIndex = questionIds.indexOf(currentQuestionId)
        const prefillQuestionIds = Object.entries(to.query)
          .map(([key, val]) => {
            const questionId = key.match(/question\[(.*)\]/)?.[1]
            if (questionId) {
              return [questionId, val]
            }
          })
          .filter(([key]) => questionIds.indexOf(key) >= currentIndex)

        if (survey.nextQuestion.nextAction === 'CompletedSurvey' || !prefillQuestionIds) {
          return next(redirectUrl)
        }

        // Pre-answer Questions
        for (const [questionId, value] of prefillQuestionIds) {
          await useSurveyStore().submitResponse({
            questionId,
            payload: {
              userId: useUserStore().userId,
              customerId: useCustomerStore().customerId,
              orderId,
              value
            }
          }).catch(err => {
            console.error(err)
          })
        }

        // Redirect to Url
        next(redirectUrl)
      } catch (err) {
        console.error(err)
        // Redirect to Url
        next(redirectUrl)
      }
    }
  },
  {
    name: 'styleguide',
    path: '/styleguide',
    component: () => import('@/views/styleguide/AppStyleguide.vue')
  },
  {
    name: 'landing',
    path: '/',
    component: () => import('@/views/landing/Landing.vue')
  },
  {
    name: 'landing-new',
    path: '/new',
    component: () => import('@/views/landing/HomeNew.vue')
  },
  {
    name: 'cart',
    path: '/cart',
    component: () => import('@/views/order/Cart.vue')
  },
  {
    name: 'offers',
    path: '/offers/:voucherCode?',
    component: () => import('@/views/menu/Offers.vue'),
    props: true
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('@/views/form/Contact.vue')
  },
  {
    name: 'franchise-thanks',
    path: '/franchise/thank-you',
    component: () => import('@/views/form/franchise/FranchiseThanks.vue')
  },
  {
    name: 'careers',
    path: '/careers-apply-now',
    component: () => import('@/views/form/Careers.vue')
  },
  {
    path: '/account',
    component: () => import('@/views/account/Account.vue'),
    beforeEnter (to, from, next) {
      const isNotAuth = !(useCustomerStore().isAuth)
      redirectGuard(to, from, next, isNotAuth, '/register')
    },
    children: [
      {
        name: 'account-details',
        path: 'details',
        component: () => import('@/views/account/Account.vue')
      },
      {
        name: 'account-vouchers',
        path: 'vouchers',
        component: () => import('@/components/account/VouchersProfile.vue')
      },
      {
        name: 'account-payment',
        path: 'payment',
        component: () => import('@/components/account/UpdatePaymentDetailsForm.vue')
      },
      {
        name: 'account-password',
        path: 'password',
        component: () => import('@/components/account/UpdatePaymentDetailsForm.vue'),
        beforeEnter (to, from, next) {
          const hasEmailAccount = useCustomerStore().hasEmailAccount
          redirectGuard(to, from, next, !hasEmailAccount, '/account/details')
        }
      },
      {
        name: 'account-history',
        path: 'history',
        component: () => import('@/components/order/OrderHistory.vue')
      },
      {
        name: 'account-app-settings',
        path: 'app-settings',
        component: () => import('@/components/account/AppSettings.vue')
      },
      {
        path: '',
        name: 'parent',
        redirect: {
          name: 'account-details'
        }
      }
    ]
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/account/Login.vue'),
    beforeEnter (to, from, next) {
      const isAuth = useCustomerStore().isAuth
      redirectGuard(to, from, next, isAuth, '/account')
    }
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/account/Register.vue'),
    beforeEnter (to, from, next) {
      const isAuth = useCustomerStore().isAuth
      
      if (from?.name?.includes('account')) {
        next('/offers')
      } else {
        redirectGuard(to, from, next, isAuth, '/account')
      }
    }
  },
  {
    name: 'paypal-redirect',
    path: '/oauth/paypal-redirect',
    component: () => import('@/views/account/PayPalRedirect.vue'),
    beforeEnter (to, from, next) {
      const isAuth = useCustomerStore().isAuth
      redirectGuard(to, from, next,   isAuth, '/')
    }
  },
  {
    name: 'oauth-redirect',
    path: '/oauth/redirect',
    component: () => import('@/views/account/OauthRedirect.vue'),
    beforeEnter (to, from, next) {
      const isAuth = useCustomerStore().isAuth
      redirectGuard(to, from, next, isAuth, '/')
    }
  },
  {
    name: 'oauth-register',
    path: '/oauth-register',
    component: () => import('@/views/account/OAuthRegister.vue'),
    beforeEnter (to, from, next) {
      const isAuth = useCustomerStore().isAuth
      redirectGuard(to, from, next, isAuth, '/account')
    }
  },
  {
    name: 'forgot-password',
    path: '/reset-password',
    component: () => import('@/views/account/ForgotPassword.vue'),
    beforeEnter (to, from, next) {
      const isAuth = useCustomerStore().isAuth
      redirectGuard(to, from, next, isAuth, '/account')
    }
  },
  {
    name: 'reset-password',
    path: '/reset-password/:customerId/:token',
    component: () => import('@/views/account/ResetPassword.vue'),
    beforeEnter (to, from, next) {
      const isAuth = useCustomerStore().isAuth
      redirectGuard(to, from, next, isAuth, '/account')
    }
  },
  {
    name: 'verify-email',
    path: '/verify-email/:customerId?/:token?',
    component: () => import('@/views/account/VerifyEmail.vue'),
    beforeEnter (to, from, next) {
      const isAuth = useCustomerStore().isAuth
      redirectGuard(to, from, next, isAuth, '/account')
    },
    meta: { caseSensitive: true },
  },
  {
    name: 'opt-out',
    path: '/preferences/:type(email|sms)/:userId?',
    component: () => import('@/views/user/OptOutView.vue'),
    props: route => ({
      optOutType: route.params.type,
      userId: route.params.userId
    }),
    meta: { caseSensitive: true }
  },
  {
    path: '/preferences/unsubscribe/sms/:userId?',
    redirect: to => {
      return { name: 'opt-out', params: { type: 'sms', userId: to.params.userId } }
    }
  },
  {
    path: '/preferences/unsubscribe/email/:userId?',
    redirect: to => {
      return { name: 'opt-out', params: { type: 'email', userId: to.params.userId } }
    }
  },
  {
    name: 'product-redirect',
    component: () => import('@/views/menu/Product.vue'),
    path: '/product/:type/:slug/:basketItemId?',
    beforeEnter: (to, from, next) => {
      UiController.showSpinner({
        dark: true,
        blocking: true,
        loading: true
      })

      next()
    }
  },
  {
    name: 'menu-category',
    path: '/menu/:category/:subcategory?',
    component: () => import('@/views/menu/MenuCategory.vue'),
    children: [
      {
        name: 'product',
        component: () => import('@/views/menu/Product.vue'),
        path: 'product/:type/:slug/:basketItemId?',
        beforeEnter: (to, from, next) => {
          UiController.showSpinner({
            dark: true,
            blocking: true,
            loading: true
          })

          next()
        }
      }
    ]
  },
  {
    name: 'coupons',
    path: '/coupons',
    component: () => import('@/views/coupons/Coupons.vue')
  },
  {
    name: 'meal',
    path: '/pizza-meals/:slug/:basketItemId?',
    component: () => import('@/views/menu/Meal.vue')
  },
  {
    name: 'checkout',
    path: '/checkout/:paymentMethod?',
    component: () => import('@/views/order/Checkout.vue'),
    async beforeEnter (to, from, next) {
      const featureFlagsStore = useFeatureFlagsStore()
      const expressPayEnabled = (await featureFlagsStore.getFeatureFlag('express_pay_checkout'))?.enabled

      // If express pay is disabled and there's a payment method in the URL, redirect to base checkout
      if (!expressPayEnabled && to.params.paymentMethod) {
        return next('/checkout')
      }

      // Accepted Express Pay checkout methods, add empty string to access /checkout
      if(!EXPRESS_PAYMENT_METHODS.map(x => x.toLowerCase()).concat('').includes(to.params.paymentMethod)) {
        return next('/checkout')
      }

      // Customer needs to be logged in with PayPal to use it as their payment method in express
      if(to.params.paymentMethod === PAYMENT.PAYPAL.id.toLowerCase() && useCustomerStore().provider !== PROVIDER.PAYPAL) {
        return next('/checkout')
      }
      
      // No need to validate if coming from offers, menu and cart
      if (['offers', 'menu-category', 'cart'].includes(from.name)) {
        return next()
      }

      // use FE validation for the min spend check instead error 412 from /validate api to isolate the error
      const valid = validateMinimumSpend(toCurrency) &&
        validateMaximumAlcohol() &&
        validateAlcoholWithFood() &&
        validatePaymentTypeOption()

      if (!valid) {
        return from.name ? next(false) : next({ name: 'offers' })
      }

      next()
    }
  },
  {
    name: 'zip-pay-confirmation',
    path: '/zippayconfirmation',
    component: () => import('@/views/order/ZipPayConfirmation.vue'),
    beforeEnter (to, from, next) {
      if (!validateMinimumSpend(toCurrency) || !to.query.checkoutId) {
        next({ name: 'offers' })
      } else {
        next()
      }
    }
  },
  {
    name: 'complete-afterpay',
    path: '/completeafterpay/:basketId',
    component: () => import('@/views/order/CompleteAfterpay.vue'),
  },
  {
    name: 'card-auth',
    path: '/card-auth',
    component: () => import('@/views/order/Auth3DSRedirectForm.vue')
  },
  {
    name: 'process-order',
    path: '/process-order/:basketId',
    component: () => import('@/views/order/ProcessOrder.vue')
  },
  {
    name: 'order',
    path: '/order/:orderId',
    component: () => import('@/views/order/Order.vue'),
    children: [
      {
        name: 'hawaiian-pizza-game',
        path: '/order/:orderId/hawaiian-pizza-game',
        component: () => import('@/views/order/HawaiianPizzaGame.vue')
      }
    ]
  },
  {
    name: 'track-order',
    path: '/track-order',
    component: () => import('@/views/order/TrackOrder.vue')
  },
  {
    name: 'stores',
    path: '/stores',
    component: () => import('@/views/store/Stores.vue')
  },
  {
    name: 'store',
    path: '/stores/pizza-hut-:storeName',
    component: () => import('@/views/store/Store.vue')
  },
  {
    name: 'blog',
    path: '/blog',
    component: () => import('@/views/content/Blog.vue'),
    children: [
      {
        name: 'blog-category',
        path: '/blog/category/:category'
      }
    ]
  },
  {
    name: 'blog-post',
    path: '/blog/:slug',
    component: () => import('@/views/content/BlogPost.vue')
  },
  {
    name: 'fatal',
    path: '/fatal',
    component: () => import('@/views/shared/Fatal.vue')
  },
  // {
  //   name: 'flick-footy',
  //   path: '/flick-footy',
  //   component: () => import('@/views/sauce-of-origin/Flick-Footy.vue') // replace this with right component
  // },
  {
    name: 'campaign-form',
    path: '/c/:slug?/:storeId?',
    component: () => import('@/views/content/Campaign.vue')
  },
  {
    name: 'cms-content',
    path: '/:slug*',
    component: () => import('@/views/content/Content.vue')
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    props: true,
    component: () => import('@/views/shared/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to, from) {
    if (
      (from.name === 'menu-category' && to.name === 'product') ||
      (from.name === 'product' && to.name === 'menu-category')
    ) {
      return false
    } else {
      return { top: 0, behavior: 'instant' }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  useLocalisationStore().resetLocalisationModal()
  if (!to.meta?.caseSensitive && to.path !== to.path.toLowerCase()) {
    next({
      path: to.path.toLowerCase(),
      query: to.query
    })
  } else {
    next()
  }

  if (to.query?.debugMode) {
    useUiStore().setDebugMode(false)
  }

  if (to.query?.localise && to.name !== 'product-redirect') {
    checkLocaliseQueryParams(to.query?.localise)
  }

  TrackingController.trackUtm(to.query)

  setProperties()
})

router.afterEach((to, from) => {
  if (to.name) {
    // save from and to route if from.href is present
    if (from?.href && from.path !== to.path) {
      useUiStore().route = {
        'from': {
          path: from.path, 
          href: from.href
        },
        'to': {
          path: to.path,
          href: to.href
        }
      }
    }
    try {

      // @GTM DATALAYER
      TrackingController.trackGA4CustomEvent('virtualPageView', {
        virtualPageView: to.fullPath,
        virtualPageTitle: to.fullPath,
        ...(useUserStore().hasUserId && { userId: useUserStore().userId })
      })

      // GA4 tracking
      TrackingController.trackGA4Screen(to)

      // PHA_DL tracking
      TrackingController.trackView({ view: to, save: true })

      useSidebarStore().toggleSidebar(false)
    } catch (err) {
      console.log(err)
    }

    // @GTM DATALAYER END
  }

  if (
    (from.name === 'menu-category' && to.name === 'product') ||
    (from.name === 'product' && to.name === 'menu-category')
  ) {
    return false
  } else {
    useCacheStore().setLastCacheCheck()
  }

  if(to.query?.localise) {
    removeQueryParam('localise')
  }

})

window.addEventListener('vite:preloadError', () => {
  window.location.reload()
})

export default router
