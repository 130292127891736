import type { paths } from '@/services/open-api/generated/discover'
import createClient from 'openapi-fetch'

const DISCOVER_API_URL = import.meta.env.VITE_API_DISCOVER

export default {
  /**
   * Fetches the configuration
   * @returns The configuration, the error and the response
   */
  async getConfiguration() {
    const { data, error, response } = await createClient<paths>({
      baseUrl: DISCOVER_API_URL,
    }).GET('/api/v1/Configuration')

    return { data, error, response }
  }
}
