import { PROVIDER } from '@/config/provider.config'
import { SocialLogin } from '@capgo/capacitor-social-login'
import { GoogleAuthWeb } from '@/services/helpers/auth/gGisFcmAuth.helper.js'

export const getToken = async (provider) => {
  const tokenHandler = {
    [PROVIDER.GOOGLE]: async () => {
      return await GoogleAuthWeb.getToken();
    },
    [PROVIDER.FACEBOOK]: async () => {
      return await SocialLogin.getAuthorizationCode({ provider: 'facebook' })
    },
    [PROVIDER.APPLE]: () => {},
    [PROVIDER.PAYPAL]: () => {}
  }

  return tokenHandler[provider]?.()
}

export const logout = (provider) => {
  const logoutHandler = {
    [PROVIDER.GOOGLE]: async () => { GoogleAuthWeb.signOut() },
    [PROVIDER.FACEBOOK]: () => SocialLogin.logout({ provider: 'facebook' }),
    [PROVIDER.APPLE]: () => {},
    [PROVIDER.PAYPAL]: () => {}
  }

  return logoutHandler[provider]?.()
}
