import  type { paths, components  } from '@/services/open-api/generated/marketing'
import { fetchClient } from '@/services/open-api/fetchClient'

export default {
  /**
   * Retrieves a list of popup offers for a given payload.
   * @param apiRoot The base URL of the API.
   * @param payload The payload object containing the parameters.
   * @returns A promise resolving to the popup offers for the given payload.
   */
  async GetUpsells ({ apiRoot, payload } : {
    apiRoot: string
    payload: components['schemas']['Serverless.Marketing.Api.Models.PopupModels.PopupRequest']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/marketing/upsells', {
      body: {
        ...payload
      }
    })
  }
}