import  type { paths, components  } from '@/services/open-api/generated/order'
import { fetchClient } from '@/services/open-api/fetchClient'

export default {
  /**
   * Retrieves the status of an order by the given `orderReference`.
   *
   * @param apiRoot The base URL of the API.
   * @param orderReference The order reference of the order to retrieve the status for.
   * @returns A promise resolving to the response of the API.
   */
  async GetOrderStatusByReference ({ apiRoot, orderReference } : {
    apiRoot: string
    orderReference: string
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).GET('/api/v1/order/status/reference/{orderReference}', {
      params: {
        path: {
          orderReference
        }
      }
    })
  },

  /**
   * Retrieves the status of an order by the given `orderId`.
   *
   * @param apiRoot The base URL of the API.
   * @param orderId The order ID of the order to retrieve the status for.
   * @returns A promise resolving to the response of the API.
   */
  async GetOrderStatusById ({ apiRoot, orderId } : {
    apiRoot: string
    orderId: string
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).GET('/api/v1/order/{orderId}/status', {
      params: {
        path: {
          orderId
        }
      }
    })
  },

  /**
   * Retrieves the status of an order by the given `orderId` and `sessionId`.
   *
   * @param apiRoot The base URL of the API.
   * @param orderId The order ID of the order to retrieve the status for.
   * @param sessionId The session ID of the order to retrieve the status for.
   * @returns A promise resolving to the response of the API.
   */
  async TrackOrderDetailsById ({ apiRoot, orderId, sessionId } : {
    apiRoot: string
    orderId: components['schemas']['Serverless.Order.Api.Models.Dto.TrackOrderRequest']['orderId']
    sessionId: components['schemas']['Serverless.Order.Api.Models.Dto.TrackOrderRequest']['sessionId']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/order/tracker', {
      body: {
        orderId,
        sessionId
      }
    })
  },

  /**
   * Retrieves the recent orders of a customer.
   *
   * @param apiRoot The base URL of the API.
   * @param customerId The ID of the customer to retrieve the recent orders for.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns A promise resolving to the response of the API.
   */
  async GetRecentOrders ({ apiRoot, customerId, options } : {
    apiRoot: string
    customerId: string,
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).GET('/api/v1/order/orderHistory/{customerId}', {
      params: {
        path: { customerId }
      }
    })
  },

  /**
   * Retrieves the recent orders of a customer in the last 8 hours.
   *
   * @param apiRoot The base URL of the API.
   * @param userId The ID of the user to retrieve the recent orders for.
   * @param options The request options.
   * @param options.headers The headers of the request.
   * @param options.headers.Authorization The authorization token of the request.
   * @returns A promise resolving to the response of the API.
   */
  async TrackOrdersByUserId ({ apiRoot, userId, options } : {
    apiRoot: string
    userId: string,
    options: {
      headers: {
        Authorization: string
      }
    }
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot, headers: options.headers }).GET('/api/v1/order/trackOrders/{userId}', {
      params: {
        path: { userId },
        query: { hourLimit: 8 }
      }
    })
  }
}