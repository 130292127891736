import router from '@/router'
import { getLastPage } from '@/services/helpers/router.helper.js'
import { getCapacitorPlatform, isCapacitorNativePlatform, isiOsApp } from '@/services/utility/capacitor.utility'
import { SplashScreen } from '@capacitor/splash-screen'
import { Preferences } from '@capacitor/preferences'
import { UiController } from '@/controllers/ui.controller'
import { Dialog } from '@capacitor/dialog'
import {
  BrowserVault,
  Vault,
  Device,
  VaultType,
  VaultErrorCodes
} from '@ionic-enterprise/identity-vault'
import { dictionary } from '@/config/dictionary.config.ts'

import { useCustomerStore } from '@/stores/customer'

const sessionKey = 'session'

let vault
let session

export const VaultController = {
  initialiseVault: async function () {
    const config = {
      key: 'com.pizzahutau',
      type: 'SecureStorage',
      deviceSecurityType: 'None',
      lockAfterBackgrounded: 5000,
      shouldClearVaultAfterTooManyFailedAttempts: true,
      unlockVaultOnLoad: false
    }

    vault = getCapacitorPlatform() === 'web' ? new BrowserVault(config) : new Vault(config)

    await SplashScreen.hide()

    const hide = await this.isHidingContentsInBackground()
    this.hideContentsInBackground(hide)

    vault.onLock(() => {
      session = undefined

      if (router.currentRoute.value.name.includes('account-')) {
        this.getSession()
      }
    })

    vault.onUnlock(() => {
      if (getLastPage() && getLastPage().includes('account-')) {
        router.replace(getLastPage())
      }
    })
  },

  setSession: async function (value) {
    if (!vault) return

    session = value
    await vault.setValue(sessionKey, value)
  },

  getSession: async function () {
    if (!session) {
      try {
        session = await vault.getValue(sessionKey)

        if (router.currentRoute.value.name.includes('account-')) {
          return
        }

        if (getLastPage() && getLastPage().includes('/account')) {
          router.replace(getLastPage())
        } else {
          router.replace('/account/details')
        }
      } catch (err) {
        console.error(err)
        if (router.currentRoute.value.name.includes('account-')) {
          router.replace('/')
        }
      }
    }
  },

  loginSession: async function () {
    if (!session) {
      try {
        // Get the value from the vault
        session = await vault.getValue(sessionKey)

        // Show spinner
        UiController.showSpinner({ loading: true })

        // Re authenticate to get the fresh data.
        // i.e the App hasn't open for a long time
        const jsonWebToken = await useCustomerStore().getRefreshToken(session.jsonWebToken.refresh_token)
        const customer = await useCustomerStore().getProfile(session.customer.customerId)

        // Set the payload data
        const payload = {
          data: {
            jsonWebToken,
            customer
          }
        }

        // Login the customer first with the existing token
        await useCustomerStore().handleLogInSuccess({ payload })
      } catch (err) {
        if (router.currentRoute.value.name.includes('account-')) {
          router.replace('/')
        }

        // Do no clear the Biometrics if the user cancels Biometrics or their biometrics fails. Allow them to try again instead.
        if ([VaultErrorCodes.UserCanceledInteraction].includes(err?.code)) {
          return
        }

        this.clearSession()
        throw err
      } finally {
        UiController.showSpinner({ loading: false })
      }
    }
  },

  clearSession: async function () {
    if (!vault) return

    session = undefined
    await vault.clear()
  },

  lockVault: async function () {
    if (!vault) return

    session = undefined
    await vault.lock()
  },

  canUnlock: async function () {
    return (await this.hasLockedData()) && (await vault.isLocked())
  },

  canHideContentsInBackground: function () {
    return isCapacitorNativePlatform()
  },

  canUseBiometrics: async function () {
    return isCapacitorNativePlatform() && (await Device.isBiometricsEnabled())
  },

  canUseSystemPasscode: async function () {
    return isCapacitorNativePlatform() && (await Device.isSystemPasscodeSet())
  },

  hideContentsInBackground: async function (value) {
    await Device.setHideScreenOnBackground(value, true)
    return Preferences.set({ key: dictionary.CAPACITOR.PREFERENCES.HIDE_IN_BACKGROUND, value: JSON.stringify(value) })
  },

  isHidingContentsInBackground: async function () {
    const { value } = await Preferences.get({ key: dictionary.CAPACITOR.PREFERENCES.HIDE_IN_BACKGROUND })
    return JSON.parse(value || 'false')
  },

  provision: async function () {
    if ((await Device.isBiometricsAllowed()) === 'prompt') {
      try {
        await Device.showBiometricPrompt({ iosBiometricsLocalizedReason: 'Please authenticate to continue' })
      } catch (err) {
        console.error(err)
      }
    }
  },

  getUnlockMode: async function () {
    const { value } = await Preferences.get({ key: dictionary.CAPACITOR.PREFERENCES.UNLOCK_MODE })
    return value || 'SecureStorage'
  },

  setUnlockMode: async function (unlockMode) {
    let type
    let deviceSecurityType

    switch (unlockMode) {
      case 'Biometrics':
        await this.provision()
        type = 'DeviceSecurity'
        deviceSecurityType = 'Biometrics'
        break

      case 'BiometricsWithPasscode':
        await this.provision()
        type = 'DeviceSecurity'
        deviceSecurityType = 'Both'
        break

      case 'SystemPasscode':
        type = 'DeviceSecurity'
        deviceSecurityType = 'SystemPasscode'
        break

      default:
        type = 'SecureStorage'
        deviceSecurityType = 'None'
    }

    await vault.updateConfig({
      ...vault.config,
      type,
      deviceSecurityType
    })

    await Preferences.set({ key: dictionary.CAPACITOR.PREFERENCES.UNLOCK_MODE, value: unlockMode })
  },

  loginAttempt: async function () {
    // Check if login attempt has value
    const { value: login } = (await Preferences.get({ key: dictionary.CAPACITOR.PREFERENCES.LOGIN_ATTEMPT }))

    if (!login) {
      // Show the dialog popup
      const { value: confirm } = await Dialog.confirm({
        okButtonTitle: 'Yes, please!',
        cancelButtonTitle: 'No thanks',
        title: 'Next time, would you like to login faster with Biometrics?',
        message: `Login with ${isiOsApp() ? 'Face ID or Touch ID' : 'Face Recognition or Fingerprint'}. It's fast, easy and secure.`
      })

      // Set the Biometrics
      if (confirm) {
        if (this.canUseBiometrics() && this.canUseSystemPasscode()) {
          this.setUnlockMode('BiometricsWithPasscode')
        } else if (this.canUseBiometrics()) {
          this.setUnlockMode('Biometrics')
        } else {
          this.setUnlockMode('SecureStorage')
        }

        // Set the initial value to null
        await Preferences.remove({ key: dictionary.CAPACITOR.PREFERENCES.LOGIN_ATTEMPT })

        return
      }

      // Set the initial value to 1
      await Preferences.set({ key: dictionary.CAPACITOR.PREFERENCES.LOGIN_ATTEMPT, value: '1' })

      return
    }

    let { value: attempt } = (await Preferences.get({ key: dictionary.CAPACITOR.PREFERENCES.LOGIN_ATTEMPT }))
    if (JSON.parse(attempt) >= 3) {
      await Preferences.remove({ key: dictionary.CAPACITOR.PREFERENCES.LOGIN_ATTEMPT })
    } else {
      attempt++
      await Preferences.set({ key: dictionary.CAPACITOR.PREFERENCES.LOGIN_ATTEMPT, value: JSON.stringify(attempt) })
    }
  },

  hasLockedData: async function () {
    const unlockMode = await this.getUnlockMode()
    const isEmpty = await vault.isEmpty()
    return !isEmpty && unlockMode !== VaultType.SecureStorage
  }
}
