/**
 * Google Authentication Web Implementation
 * Handles Google Sign-In flow for web applications using Google Identity Services with FedCM support
 */

const SCRIPT_ID = 'google-platform';

// Private variables
let gapiLoaded = null;
let tokenClient = null;
let currentAccessToken = null;
let options = {
  clientId: '',
  scopes: [],
  grantOfflineAccess: false
};
const listeners = new Map();

/**
 * Google Auth instance for web applications
 * @type {Object} Google Auth methods and utilities
 */
export const GoogleAuthWeb = {
  /**
   * Loads the Google Identity Services script
   * @private
   */
  _loadScript() {
    if (typeof document === 'undefined') return;

    const existingScript = document.getElementById(SCRIPT_ID);
    if (existingScript) return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.async = true;
    script.id = SCRIPT_ID;
    script.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(script);
  },

  /**
   * Notifies all listeners of an event
   * @private
   */
  _notifyListeners(eventName, data) {
    const callbacks = listeners.get(eventName);
    if (callbacks?.length) {
      callbacks.forEach((callback) => {
        try {
          callback(data);
        } catch (error) {
          console.error(`GoogleAuth: Listener callback error:`, error);
        }
      });
    }
  },

  /**
   * Initialises the Google Auth client
   * @param {Object} initOptions - Configuration options
   * @param {string} initOptions.clientId - Google OAuth client ID
   * @param {string[]} [initOptions.scopes=[]] - OAuth scopes to request
   * @param {boolean} [initOptions.grantOfflineAccess=false] - Whether to request offline access
   */
  async initialise(initOptions = {}) {
    if (typeof window === 'undefined') {
      throw new Error('GoogleAuth: Window is undefined - must be run in browser context');
    }

    const { clientId, scopes = [], grantOfflineAccess = false } = initOptions;

    if (!clientId) {
      throw new Error('GoogleAuth: Client ID is required');
    }

    options = { clientId, scopes, grantOfflineAccess };

    gapiLoaded = new Promise((resolve, reject) => {
      if (document.getElementById(SCRIPT_ID)) {
        resolve();
      } else {
        this._loadScript();
        const checkLoaded = setInterval(() => {
          if (window.google?.accounts?.oauth2) {
            clearInterval(checkLoaded);
            resolve();
          }
        }, 100);

        setTimeout(() => {
          clearInterval(checkLoaded);
          reject(new Error('GoogleAuth: Failed to load Google Identity Services'));
        }, 10000);
      }
    });

    await gapiLoaded;

    // Initialize Google Identity Services with FedCM enabled
    try {
      window.google.accounts.id.initialize({
        client_id: options.clientId,
        callback: (response) => {
          if (response?.credential) {
            this._notifyListeners('idToken', response.credential);
          }
        },
        use_fedcm_for_prompt: true
      });
    } catch (error) {
      // Check for FedCM disabled error
      if (error.message?.includes('FedCM')) {
        console.warn('GoogleAuth: FedCM was disabled in browser Site Settings. Authentication will fall back to default flow.');
        // Reinitialize without FedCM
        window.google.accounts.id.initialize({
          client_id: options.clientId,
          callback: (response) => {
            if (response?.credential) {
              this._notifyListeners('idToken', response.credential);
            }
          },
          use_fedcm_for_prompt: false
        });
      } else {
        throw error;
      }
    }

    // Initialize the token client for access token
    tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: options.clientId,
      scope: options.scopes.join(' '),
      callback: '', // defined at request time
      error_callback: (err) => {
        console.error('GoogleAuth: Token client error:', err);
        this._notifyListeners('error', err);
      },
      prompt: 'select_account'
    });
  },

  /**
   * Initiates the Google Sign-In flow
   * @returns {Promise<Object>} User profile and authentication data
   */
  async signIn() {
    try {
      await gapiLoaded;

      let serverAuthCode = null;

      // Handle offline access if requested
      if (options.grantOfflineAccess) {
        try {
          await window.google.accounts.oauth2.initCodeClient({
            client_id: options.clientId,
            scope: options.scopes.join(' '),
            callback: (response) => {
              if (response.code) {
                serverAuthCode = response.code;
              }
            },
            prompt: 'consent'
          }).requestCode();
        } catch (error) {
          console.warn('GoogleAuth: Failed to get offline access:', error);
          // Continue with regular sign in even if offline access fails
        }
      }

      // Get access token
      const tokenResponse = await new Promise((resolve, reject) => {
        tokenClient.callback = (resp) => {
          if (resp.error) {
            reject(new Error(`GoogleAuth: ${resp.error}`));
            return;
          }
          resolve(resp);
        };

        try {
          tokenClient.requestAccessToken();
        } catch (error) {
          reject(new Error(`GoogleAuth: Failed to request token - ${error.message}`));
        }
      });

      if (!tokenResponse?.access_token) {
        throw new Error('GoogleAuth: Failed to get access token');
      }

      currentAccessToken = tokenResponse.access_token;

      // Get the ID token using google.accounts.id with FedCM
      const idTokenResult = await new Promise((resolve) => {
        let idTokenReceived = false;

        // Set up callback for ID token
        const handleCredentialResponse = (response) => {
          if (response?.credential) {
            idTokenReceived = true;
            resolve({ credential: response.credential });
          }
        };

        // Initialize Google Identity Services
        window.google.accounts.id.initialize({
          client_id: options.clientId,
          callback: handleCredentialResponse,
          use_fedcm_for_prompt: true
        });

        // Request the ID token
        try {
          // Render the button container (hidden)
          const buttonContainer = document.createElement('div');
          buttonContainer.style.display = 'none';
          document.body.appendChild(buttonContainer);

          // Render the Sign In With Google button
          window.google.accounts.id.renderButton(
            buttonContainer,
            { type: 'standard', size: 'large', text: 'sign_in_with' }
          );

          // Trigger One Tap prompt
          window.google.accounts.id.prompt();

          // Cleanup after timeout
          setTimeout(() => {
            if (!idTokenReceived) {
              document.body.removeChild(buttonContainer);
              resolve(null);
            }
          }, 30000);
        } catch (error) {
          console.warn('GoogleAuth: Error during ID token request:', error);
          // Fallback to non-FedCM flow if needed
          window.google.accounts.id.initialize({
            client_id: options.clientId,
            callback: handleCredentialResponse,
            use_fedcm_for_prompt: false
          });
          window.google.accounts.id.prompt();
        }
      });

      // Get user info using the access token
      const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          'Authorization': `Bearer ${tokenResponse.access_token}`
        }
      });

      if (!response.ok) {
        throw new Error(`GoogleAuth: Failed to fetch user info - ${response.statusText}`);
      }

      const userInfo = await response.json();

      const userData = {
        email: userInfo.email,
        familyName: userInfo.family_name,
        givenName: userInfo.given_name,
        id: userInfo.sub,
        imageUrl: userInfo.picture,
        name: userInfo.name,
        authentication: {
          accessToken: tokenResponse.access_token,
          idToken: idTokenResult?.credential || '',
          refreshToken: '',
          serverAuthCode: serverAuthCode
        },
      };

      this._notifyListeners('signIn', userData);
      return userData;
    } catch (error) {
      this._notifyListeners('error', error);
      throw error;
    }
  },

  /**
   * Signs out the current user and revokes access
   */
  async signOut() {
    try {
      await gapiLoaded;

      if (currentAccessToken) {
        await window.google?.accounts?.oauth2?.revoke(currentAccessToken);
        currentAccessToken = null;
        this._notifyListeners('signOut');
      }
    } catch (error) {
      console.error('GoogleAuth: Sign out failed:', error);
      this._notifyListeners('error', error);
      throw error;
    }
  },

  /**
   * Gets the ID token for the current session
   * @returns {Promise<string|null>} The ID token if available, null otherwise
   */
  async getToken() {
    try {
      await gapiLoaded;

      return new Promise((resolve) => {
        window.google.accounts.id.initialize({
          client_id: options.clientId,
          callback: (response) => {
            resolve(response?.credential || null);
          }
        });

        window.google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            resolve(null);
          }
        });
      });
    } catch (error) {
      console.error('GoogleAuth: Failed to get token:', error);
      return null;
    }
  },

  /**
   * Adds an event listener
   * @param {string} eventName - Event name to listen for
   * @param {Function} callback - Callback function
   */
  on(eventName, callback) {
    if (!listeners.has(eventName)) {
      listeners.set(eventName, []);
    }
    listeners.get(eventName).push(callback);
  }
};
