// TODO: need to be converted to composable once migrated to vue 3

import { App } from '@capacitor/app'
import { getCapacitorPlatform } from '@/services/utility/capacitor.utility'
import { getURLSearchQueryParams } from '@/services/utility/query-parameters.utility.js'

export const getAttributions = async () => {
  const query = getURLSearchQueryParams()
  const customerInfo = {}
  if (query.utm_campaign) {
    customerInfo.utmCampaign = query.utm_campaign
  }
  if (query.utm_medium) {
    customerInfo.utmMedium = query.utm_medium
  }
  if (query.utm_source) {
    customerInfo.utmSource = query.utm_source
  }
  if (query.utm_term) {
    customerInfo.utmTerm = query.utm_term
  }
  if (query.utm_content) {
    customerInfo.utmContent = query.utm_content
  }
  if (query.referring_domain) {
    customerInfo.referringDomain = query.referring_domain
  }

  const attributions = {
    customerInfo,
    appType: getCapacitorPlatform(),
    appVersion: await getAppVersion()
  }

  return attributions
}

export const getAppVersion = async () => {
  if (getCapacitorPlatform() === 'web') {
    return import.meta.env.VITE_VERSION
  } else {
    try {
      const info = await App.getInfo()
      if (info) {
        return `v${info.version}`
      } else {
        return null
      }
    } catch (error) {
      console.error('Failed to get app version.', error)
      return null
    }
  }
}
