import UserAPI from '@/services/api/user.api'
import dayjs from 'dayjs'
import { TrackingController } from '@/controllers/tracking.controller'
import { setCrashlyticsUserIdAndBasketId } from '@/services/utility/crashlytics.utility'
import { changeUser, logCustomEvent } from '@/services/utility/braze.utility'
import { defineStore } from 'pinia'
import { isTruthyUUID } from '@/services/utility/uuid.utility'
import { useDiscoverStore } from '@/stores/discover'

interface UserState {
  userId: string
  userStatus: string
}

interface GetEntryPayload {
  orderId: string
  customerId?: string
  userId: string
  prizeTypeId: string
  forceResponse?: string
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    userId: '00000000-0000-0000-0000-000000000000',
    userStatus: ''
  }),

  getters: {
    hasUserId: state => isTruthyUUID(state.userId),
  },

  actions: {
    setUser (payload: string) {
      this.userId = payload

      changeUser(payload)

      TrackingController.trackLegacyEvent('setUserId', {
        userId: payload
      })
      setCrashlyticsUserIdAndBasketId()
    },
    setUserStatus (payload: boolean) {
      this.userStatus = payload ? 'new' : 'returning'
    },
    async emailOptOut (userId: string) {
      const apiRoot = useDiscoverStore().uris?.API_USER || ''
      const response = await UserAPI.EmailOptOut({
        apiRoot,
        userId,
        payload: {
          optinEmail: null
        }
      })
      const user = response.data
      if (user?.userId) {
        changeUser(user.userId)
        logCustomEvent('email_unsubscribe', { date: dayjs().format() })
      }
      return user
    },
    async emailOptOutByEmail (data: { email: string, tokenV3: string }) {
      const apiRoot = useDiscoverStore().uris?.API_USER || ''
      const optoutTimestamp = dayjs().format()
      const response = await UserAPI.EmailOptOutByEmail({
        apiRoot,
        payload: {
          ...data,
          userId: useUserStore().userId,
          optoutTimestamp
        }
      })
      const user = response.data
      if (user?.userId) {
        changeUser(user.userId)
        logCustomEvent('email_unsubscribe', { date: optoutTimestamp })
      }
      return user
    },
    async getEntry (payload: GetEntryPayload) {
      const apiRoot = useDiscoverStore().uris?.API_USER || ''
      const response = await UserAPI.GetEntry({ apiRoot, payload })
      return response.data
    },
    async smsOptOutShort (shortUserId: string) {
      const apiRoot = useDiscoverStore().uris?.API_USER || ''
      const response = await UserAPI.SMSOptOutShort({ apiRoot, shortUserId })
      const user = response.data
      if (user?.userId) {
        changeUser(user.userId)
        logCustomEvent('sms_unsubscribe', { date: dayjs().format() })
      }
      return user
    },
    async smsOptOutMobileNumber (data: { mobileNumber: string, tokenV3: string }) {
      const apiRoot = useDiscoverStore().uris?.API_USER || ''
      const optoutTimestamp = dayjs().format()
      const response = await UserAPI.SMSOptOutMobileNumber({
        apiRoot,
        payload: {
          ...data,
          optoutTimestamp
        }
      })
      const user = response.data
      if (user?.userId) {
        changeUser(user.userId)
        logCustomEvent('sms_unsubscribe', { date: optoutTimestamp })
      }
      return user
    },
    async campaignForm (payload) {
      const apiRoot = useDiscoverStore().uris?.API_USER || ''
      const response = await UserAPI.CampaignForm({ apiRoot, payload })

      return response.data
    }
  }
})
