import SurveyApi from '@/services/api/survey.api'
import { defineStore } from 'pinia'

import { useDiscoverStore } from '@/stores/discover'

export const useSurveyStore = defineStore('survey', {
  state: () => ({
    survey: null,
    orderId: '',
    currentQuestionId: '',
    active: false,
    focused: false
  }),

  getters: {
    pages: state => state.survey?.pages,
    questions: state => state.survey?.pages?.flatMap(p => p.sections.flatMap(s => s.questions)),
  },

  actions: {
    async getSurvey ({ surveyId, orderId, focused }) {
      const apiRoot = useDiscoverStore().uris.API_SURVEY
      try {
        const response = await SurveyApi.GetSurvey({ apiRoot, surveyId, orderId })

        const survey = response.data
        this.orderId = orderId

        this.survey = survey
        this.setActive(!!(survey))
        this.setFocused(!!(focused))

        const currentQuestion = survey?.nextQuestion?.nextQuestionId
        this.currentQuestionId = currentQuestion

        return survey
      } catch (err) {
        this.clearSurvey()
        throw err
      }
    },

    async submitResponse ({ questionId, payload }) {
      const apiRoot = useDiscoverStore().uris.API_SURVEY
      const surveyId = import.meta.env.VITE_SURVEY_ID

      const response = await SurveyApi.SendResponse({
        apiRoot,
        surveyId,
        questionId,
        payload
      })

      // API Next Question
      let nextQuestion = response.data.nextQuestionId

      // Calculate next question if not returned in API response
      if (!nextQuestion) {
        const questions = this.questions
        const index = questions.findIndex(q => q.questionId === questionId)
        nextQuestion = questions.length !== index + 1 ? questions[index + 1]?.questionId : null
      }

      // Set next question of survey
      this.currentQuestionId = nextQuestion

      return response.data
    },

    setActive (active) {
      this.active = active
    },

    setFocused (focused) {
      this.focused = focused
    },

    clearSurvey () {
      this.survey = {}
      this.orderId = ''
      this.active = false
      this.currentQuestionId = ''
      this.focused = false
    },

    completeSurvey () {
      this.clearSurvey()
    },
  }
})
