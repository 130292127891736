<template>
  <div
    class="app-spinner">
    <div class="spinner">
      <img src="/img/pizza-hut-logo.png" height="140"/>
    </div>
    <span class="loader">
      <img src="/img/pizza-hut-loading.gif" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.app-spinner {
  width: 140px;
  height: 140px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: fixed;
  z-index: map-get($zindex, spinner);

  &:before {
    animation: fade-in 200ms 1 ease-in;
    backface-visibility: hidden;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    perspective: 1000;
    transform: translate3d(0, 0, 0);
    top: 0;
    width: 100%;
    z-index: map-get($zindex, spinner);
  }
}

.spinner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 140px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 140px;
  z-index: map-get($zindex, spinner);
}

.loader {
  bottom: calc(50% - 245px);
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1000;
}
</style>
