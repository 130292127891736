interface ScheduleFilterQuery {
  days: { any_in_array: number };
  from_time: { lt_float: number };
  to_time: { gt_float: number };
  from_date: { lt_date: string };
  to_date: { gt_date: string };
  service_type: { any_in_array: string };
  'store_codes.data': { any_in_array: string }
}

export default {
  GetSpace () {
    return window.Storyblok.get('cdn/spaces/me')
  },
  GetContentBySlug (slug: string) {
    return window.Storyblok.get(`cdn/stories/content/${slug}`)
  },
  GetContentCampaignBySlug (slug: string) {
    return window.Storyblok.get(`cdn/stories/content/campaign/${slug}`)
  },
  GetCampaignBySlug ({ slug, query }: { slug: string, query: ScheduleFilterQuery }) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: `campaigns/${slug}`,
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetCokeCampaign ({ query }: { query: ScheduleFilterQuery }) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: 'campaigns/coke-campaign',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetBlogPosts (options: { page: number, per_page: number }) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: 'blog',
      ...options
    })
  },
  GetBlogPostBySlug (slug: string) {
    return window.Storyblok.get(`cdn/stories/blog/${slug}`, {
      resolve_relations: 'ContentCarousel.carousel,post.related_posts'
    })
  },
  GetOffers (query: ScheduleFilterQuery) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: 'carousels/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetCoupons (query: ScheduleFilterQuery) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: 'coupons/',
      sort_by: '',
      filter_query: query
    })
  },
  GetBanner (query: ScheduleFilterQuery) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'banners/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetBannerSecondary (query: ScheduleFilterQuery) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'banners-secondary/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetBannerSub (query: ScheduleFilterQuery) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'banners-sub/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetSnippet ({ path, query }: { path: string, query: ScheduleFilterQuery }) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: `snippets/${path}/`,
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetNavigationMenuBySlug (slug: string) {
    return window.Storyblok.get('cdn/stories/', {
      starts_with: `navigation/${slug}`,
      resolve_links: 'url'
    })
  },
  GetStore ({ query }: { query: ScheduleFilterQuery }) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'stores/',
      filter_query: query
    })
  },
  GetSpinner (query: ScheduleFilterQuery) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'spinners/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetSplashScreen (query: ScheduleFilterQuery) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'splash_screens/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetSpinTheWheel (query: ScheduleFilterQuery) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: 'spin-to-win/',
      sort_by: 'published_at:desc',
      filter_query: query
    })
  },
  GetStory (fullSlug: string) {
    return window.Storyblok.get(`cdn/stories/${fullSlug}`)
  },
  GetBlackListedUserAgents (slug: string) {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: slug
    })
  },
  GetBlackListedIRPID (slug: string) {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: slug
    })
  },
  GetDictionary () {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: 'dictionary',
      per_page: 500
    })
  },
  GetOffersCategories (slug: string) {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: slug
    })
  },
  GetFeatureFlags () {
    return window.Storyblok.get('cdn/datasource_entries', {
      datasource: 'feature-flag'
    })
  },
  GetMenuCategories ({ path, query }: { path: string, query: ScheduleFilterQuery }) {
    return window.Storyblok.get('cdn/stories', {
      starts_with: `menu-categories/${path}/`,
      sort_by: 'published_at:desc',
      filter_query: query
    })
  }
}
