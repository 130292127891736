export const dictionary = {
  DISCOVER: {
    GET_WAIT: {
      message: 'Please wait while we prepare your session!'
    },
    GET_ERROR: {
      message: ['Something went wrong!', 'We apologise for the inconvenience.']
    },
  },
  BASKET: {
    BASKET_INVALID_NOTICE: {
      text: 'We need a few details before you can proceed.'
    },
    DELETE_BASKET_CONFIRM: {
      text: 'Are you sure you want to empty your cart?'
    },
    BASKET_RESETTING: {
      text: 'Emptying your cart...'
    },
    BASKET_RESET_SUCCESS: {
      text: 'Your cart has been emptied.'
    },
    BASKET_RESET_ERROR: {
      text: 'Error emptying your cart. Please try again.'
    },
    BASKET_VALIDATING_ORDER: {
      text: 'Checking order... Please do not refresh the page...'
    },
    BASKET_VALIDATION_ERROR: {
      text: 'Sorry, there was an issue validating your details. Please try again or call 13 11 66.'
    },
    BASKET_PLACING_ORDER: {
      text: 'Placing order... Please do not refresh the page...'
    },
    BASKET_EMPTY_WARNING: {
      text: 'Your cart is currently empty. Browse the menu and start your order.'
    },
    BASKET_MINIMUM_WARNING (orderMinimum, orderType) {
      return {
        text: `You have not reached our ${orderType} minimum of ${orderMinimum}. Please add more items to your cart.`
      }
    },
    BASKET_ORDER_CANCELLED: {
      text: 'Sorry, your order has been cancelled by the store. Please call 13 11 66.'
    },
    BASKET_ORDER_ERROR: {
      text: 'Sorry, there was an issue placing your order. Please try again or call 13 11 66.'
    },
    BASKET_CARD_VERIFICATION: {
      text: 'Please wait one moment while we verify your card details.'
    },
    BASKET_LOCKED_ERROR: {
      text: 'Sorry, we\'ve detected an issue with your order. For your security, we\'ve reset your session. Please call 13 11 66.'
    },
    VALIDATE_BASKET_ERROR: {
      text: 'Sorry, there was an issue validating your cart. Please try again or call 13 11 66.'
    },
    VALIDATE_ORDER_ERROR: {
      text: 'Sorry, there was an issue validating your orders. Please try again or call 13 11 66.'
    },
    STOCK_PROMO_LIMIT_REACHED: {
      text: 'Whoa! You’ve hit the max of 3 FREE drinks per order—add more food, but no more free drinks this time!'
    },
  },
  DISPOSITION: {
    NO_STORES_FOUND_FOR_ADDRESS: {
      text: 'Sorry, we could not find a store that delivers to your address. You may be able to pick up from a nearby store.'
    },
    SAVE_SUCCESS_DELIVERY: {
      text: 'Delivery details saved'
    },
    SAVE_SUCCESS_PICKUP: {
      text: 'Pickup details saved'
    },
    SAVE_ERROR: {
      text: 'Sorry, there was an issue saving your details. Please try again or call 13 11 66.'
    },
    INVALID_SESSION_START_BUTTON_TITLE: {
      text: 'Start your order'
    },
    ONLINE_ORDER_OFF: {
      text: 'Unfortunately this store is not currently taking Online Orders. Please try again later'
    }
  },
  PRODUCT: {
    PRODUCT_CUSTOMIZATION_MIN_MAX_ERROR: ({ productName, boundType, boundValue, customizationType }) => {
      return {
        text: `The ${productName} can only contain a ${boundType} of ${boundValue} ${customizationType}.`
      }
    },
    ADD_TO_BASKET_SUCCESS: (basketItem = 'product') => {
      return {
        text: `Added ${basketItem} to the cart!`
      }
    },
    ADD_TO_BASKET_ERROR: (basketItem = 'this product') => {
      return {
        text: `There was an error adding ${basketItem} to the cart. Please try again.`
      }
    },
    UPDATE_IN_BASKET_SUCCESS: (basketItem = 'product') => {
      return {
        text: `Updated ${basketItem} in the cart!`
      }
    },
    UPDATE_IN_BASKET_ERROR: (basketItem = 'this product') => {
      return {
        text: `There was an error updating ${basketItem} in the cart. Please try again.`
      }
    },
    DELETE_FROM_BASKET_SUCCESS: (basketItem = 'product') => {
      return {
        text: `Removed ${basketItem} from the cart!`
      }
    },
    DELETE_FROM_BASKET_ERROR: (basketItem = 'this product') => {
      return {
        text: `There was an error removing ${basketItem} from the cart. Please try again.`
      }
    },
    PRODUCT_NOT_FOUND: {
      text: 'Product not found'
    },
    PRODUCT_ERROR: {
      text: 'There was an error retrieving this product. Please try again or contact support on 13 11 66.'
    },
    NO_COUPON_ACCESS_ERROR: {
      text: 'This deal requires a valid coupon to access.'
    },
    INCOMPLETE_PRODUCT: {
      text: 'Sorry, we are out of stock for items in this meal.'
    },
    INVALID_PRODUCT: {
      text: 'The product is not valid.'
    },
    CTA_BUTTON_TITLE: {
      ADD_PRODUCT: {
        text: 'Add to Cart'
      },
      UPDATE_PRODUCT: {
        text: 'Update Cart'
      },
      ADD_DEAL: {
        text: 'Add Deal to Cart'
      },
      UPDATE_DEAL: {
        text: 'Update Deal in Cart'
      },
      OUT_OF_STOCK: {
        text: 'Sold Out'
      },
      ADD_TO_DEAL: {
        text: 'Add to Deal'
      }
    },
    ALCOHOL: {
      EXCEEDED: (maxAlcoholUnitCount) => {
        return {
          text: `Sorry, you have exceeded the limit of alcohol we are allowed to sell in this order (Max ${maxAlcoholUnitCount} Units). Please review your cart.`
        }
      },
      MAX: (maxAlcoholUnitCount) => {
        return {
          text: `Sorry, you have reached the limit of alcohol we are allowed to sell in this order (Max ${maxAlcoholUnitCount} Units). Please review your cart.`
        }
       },
      NO_FOOD: {
        text: 'As your cart contains alcohol, you need to also purchase Pizza, Wings, Meatballs or Pasta before you can check out. Please review your cart.'
      }
    }
  },
  SESSION: {
    SESSION_EXPIRED: {
      text: 'Your session has expired. Please proceed to reset your session.'
    },
    SESSION_RESETTING: {
      text: 'Resetting your session...'
    },
    SESSION_RESET_SUCCESS: {
      text: 'Your session has been reset.'
    },
    SESSION_RESET_ERROR: {
      text: 'There was an error resetting your session. Please try again or contact support.'
    }
  },
  STORE: {
    GLOBAL_PHONE: {
      text: '13 11 66'
    },
    POS: {
      OFFLINE: {
        text: 'Store is currently offline. Please select another store or call 13 11 66'
      }
    }
  },
  META: {
    TITLE: {
      text: 'Pizza Hut Delivery & Takeaway | Order Online Now'
    },
    DESCRIPTION: {
      text: 'Order Pizza Hut online now. Choose from our delicious menu, all delivered hot & fresh to your door, or takeaway. Coupons & deals available. Order now!'
    },
    KEYWORDS: {
      text: ''
    },
    USER: {
      text: 'PizzaHutAU'
    },
    SITENAME: {
      text: 'Pizza Hut Australia'
    },
    IMAGE: {
      url: 'https://assets.pizzahut.com.au/f/49069/1200x600/684b132cec/social-link.jpg'
    },
    IOS_APP: {
      text: 'app-clip-bundle-id=com.yum.pizzahutau.clip, app-id=353096574'
    }
  },
  RECAPTCHA: {
    ACTION: {
      REGISTER: 'REGISTER_USER',
      CAMPAIGN_FORM: 'CAMPAIGN_FORM',
      VERIFY_EMAIL: 'VERIFY_EMAIL',
      FORGOT_PASSWORD: 'FORGOT_PASSWORD',
      RESET_PASSWORD: 'RESET_PASSWORD',
      UNSUBSCRIBE: 'UNSUBSCRIBE',
      CUSTOMER_TOKEN: 'CUSTOMER_TOKEN'
    }
  },
  ORDER: {
    TRACK: {
      ORDER_NOT_TRACKING: {
        text: 'Oops, your order tracking didn\'t load.  Please select the "Refresh" button below and we\'ll try again.'
      },
      ORDER_NOT_FOUND: {
        text: 'We are unable to track your order status at this time. Please check your order reference number or contact your local store on 13 11 66.'
      },
      ORDER_RESTRICTED: {
        text: 'You can no longer track this order.'
      },
      ERROR: {
        text: 'Something went wrong!'
      }
    },
    APPLE_PAY: {
      LOADING: {
        text: 'Loading Apple Pay...'
      }
    },
    ZIP_PAY: {
      REDIRECT: {
        text: 'Please wait while we redirect your payment to Zip.'
      },
      PROCESS: {
        text: 'Please wait while we verify your payment to Zip.'
      },
      DEFAULT: {
        text: 'Something went wrong. Please choose another payment method.'
      },
      CANCELLED: {
        text: 'Your payment to Zip was not processed. Please choose another payment method.'
      },
      DECLINED: {
        text: 'Your Zip application was declined. Please choose another payment method.'
      }
    },
    AFTERPAY: {
      PROCESS: {
        text: 'Please wait while we verify your payment to Afterpay.'
      }
    },
    HISTORY: {
      COUPON_POPOVER: {
        text: 'This order included a time limited coupon which may not be available today.'
      }
    },
    CLICK_TO_PAY: {
      SUCCESS: 'Complete!',
      ERROR: 'Whoops! There was an error loading Click to Pay.'
    }
  },
  CUSTOMER: {
    ERROR: {
      text: 'Something went wrong!'
    },
    ADD_CARD_TEXT: {
      text: 'To add / change saved payment details, place an order and select "save this credit card for future orders" on the payment page.'
    },
    FAILED_CARD_TEXT: {
      text: 'We recorded an issue with this card during your last transaction.'
    },
    SAVED_CARD_FAIL_ERROR: {
      text: 'Your order went though successfully but we were unable to save your card.  Please try saving it again next time.'
    },
    SERVICE_PREFERENCE: {
      text: 'Select either option to set your favourite Order Type. E.g. Delivery or Pickup. Your favourite Order Type will be selected automatically when you log in. You can only choose a preferred order type once you have saved both an address and a store.'
    },
    OVERWRITE_DISPOSITION: (basketServiceType, serviceType, savedDeliveryAddress, savedPickupStore) => {
      const sameServiceType = basketServiceType.toLowerCase() === serviceType.toLowerCase()
      const serviceTypeMsg = `change to ${serviceType} and `
      const dispoType = serviceType.toLowerCase() === 'delivery' ? 'delivery address' : 'pickup store'
      const savedType = serviceType.toLowerCase() === 'delivery' ? savedDeliveryAddress : savedPickupStore
      return {
        text: `Would you like to ${sameServiceType ? '' : serviceTypeMsg} use your saved ${dispoType} instead? (${savedType})`
      }
    }
  },
  MARKETING: {
    OPTIN: {
      EMAIL_SMS: {
        text: 'YES! I want to receive EXCLUSIVE offers, deals and discounts from Pizza Hut!'
      },
      SUPERCARS: {
        text: 'Are you a Supercars fan?'
      }
    }
  },
  FORMS: {
    ERROR: {
      text: 'Whoops! There was an error submitting the form. Please try again later.'
    },
    VALIDATION_ERROR: {
      text: 'Whoops! There are incomplete required fields. Please complete them.'
    },
    OPTIONS: {
      DEFAULT_SELECT: { text: 'Select One', value: null },
      DEFAULT_SELECT2: { text: 'Please Select', value: '' },
      BINARY: [
        { text: 'No', value: false },
        { text: 'Yes', value: true }
      ]
    }
  },
  CAMPAIGN: {
    ERROR: {
      DUPLICATE_EMAIL: 'Sorry, you are only allowed 1 FREE Pizza coupon each day.'
    }
  },
  PAYPAL_SIGNIN: {
    SIGNIN: {
      text: 'Please wait while we sign you in with PayPal'
    }
  },
  APPLE_SIGNIN: {
    SIGNIN: {
      text: 'Please wait while we sign you in with Apple'
    }
  },
  CAPACITOR: {
    UPDATES: {
      DOWNLOADING: (percent) => {
        return {
          text: `Downloading updates ... ${percent}%`
        }
      },
      EXTRACTING: (percent) => {
        return {
          text: `Extracting updates ... ${percent}%`
        }
      },
      SOMETHING_WRONG: {
        text: 'Something went wrong. Please restart the app.'
      }
    },
    NETWORK: {
      ONLINE: {
        text: 'Your internet connection was restored.'
      },
      OFFLINE: {
        text: 'You are currently offline.'
      }
    },
    PREFERENCES: {
      APP_INIT_URL: 'appInitUrl',
      HIDE_IN_BACKGROUND: 'hide-in-background',
      UNLOCK_MODE: 'LastUnlockMode',
      LOGIN_ATTEMPT: 'loginAttempt',
      PREVENT_RELOAD_IN_BACKGROUND: 'prevent-reload-in-background',
      DOWNLOAD_IN_PROGRESS: 'download-in-progress'
    }
  }
}
