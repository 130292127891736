import cloneDeep from 'lodash/cloneDeep'
import type { FeatureFlag } from '@braze/web-sdk'
import type { LiveActivityOptions } from '@pizzahutau/capacitor-plugin-braze'
import { BasketController } from '@/controllers/basket.controller.js'
import { Braze } from '@pizzahutau/capacitor-plugin-braze'
import { UpsellController } from '@/controllers/upsell.controller.js'
import { isOldAndroidApp, isOldiOSApp } from '@/services/utility/capacitor.utility'
import { isTruthyUUID } from './uuid.utility'
import { replaceImageDomain } from '@/services/utility/storyblok.image.utility'

import { useCartStore } from '@/stores/cart'
import { useCampaignStore } from '@/stores/campaign'
import { useContentCardsStore } from '@/stores/content-cards'
import { useFeatureFlagsStore } from '@/stores/feature-flags'

export const initBraze = async (apiKey: string, sdkUrl: string) => {
  await Braze.initialise({
    apiKey,
    options: {
      baseUrl: sdkUrl,
      enableLogging: import.meta.env.DEV,
      minimumIntervalBetweenTriggerActionsInSeconds: 0,
      manageServiceWorkerExternally: true,
      allowUserSuppliedJavascript: true
    }
  })

  // mainly use for GTM events
  window.appboy = {
    logCustomEvent
  }

  window.brazeWeb = {
    getUser: Braze.getUser,
    changeUser: Braze.changeUser
  }

  await Braze.subscribeToContentCardsUpdates((callback) => {
    if (callback.cards.length >= 1) {
      useContentCardsStore().setContentCards(
        callback.cards
      )
    }
  })

  await Braze.subscribeToFeatureFlagsUpdates((callback) => {
    if (callback.featureFlags.length >= 1) {
      useFeatureFlagsStore().setFeatureFlags(
        callback.featureFlags
      )
    }
  })

  Braze.openSession()
  initBrazePush()
}

export const initBrazePush = async (): Promise<void> => {
  Braze.subscribeToInAppMessage(null, async function (inAppMessage) {
    let shouldDisplay = true
    if (inAppMessage) {
      // Read the key-value pair for msg-id
      const msgId = inAppMessage?.extras?.['msg-id']
      const type = inAppMessage?.extras?.type

      // If this is our push primer message
      if (msgId === 'push-primer') {
        // We don't want to display the soft push prompt to users on browsers that don't support push, or if the user
        // has already granted/blocked permission
        if (await !Braze.isPushSupported() || await Braze.isPushPermissionGranted() || await Braze.isPushBlocked()) {
          shouldDisplay = false
        }

        // @ts-ignore - Need to export correct in app message types to fix this
        if (inAppMessage.buttons[0] != null) {
          // @ts-ignore - Need to export correct in app message types to fix this
          // Prompt the user when the first button is clicked
          inAppMessage.buttons[0].subscribeToClickedEvent(function () {
            Braze.registerAppboyPushMessages()
          })
        }
      } else if (type) {
        // Catch error to ensure message isn't displayed
        try {
          const { display } = await handleType(type, inAppMessage)
          shouldDisplay = display
        } catch (err) {
          shouldDisplay = false
        }
      }
    }

    // Display the message
    if (shouldDisplay) {
      Braze.showInAppMessage(inAppMessage)
    }
  })

  Braze.logCustomEvent({
    eventName: 'prime-for-push'
  })
}

export const changeUser = (userId: string): void => {
  if (!isTruthyUUID(userId)) {
    return
  }

  return Braze.changeUser({
    // avoid shorthand here. android cannot read it
    // eslint-disable-next-line object-shorthand
    userId: userId
  })
}

export const logCustomEvent = (name: string, properties = {}): boolean => {
  return Braze.logCustomEvent({
    eventName: name,
    eventProperties: properties
  })
}

export const setCustomUserAttribute = (name: string, value: string) => {
  return Braze.setCustomUserAttribute({
    eventName: name,
    value: value
  })
}

export const incrementCustomUserAttribute = (name: string, value?: number) => {
  return Braze.incrementCustomUserAttribute({
    eventName: name,
    incrementValue: value
  })
}
export const addToCustomAttributeArray = (name: string, value: string) => {
  return Braze.addToCustomAttributeArray({
    eventName: name,
    arrayValue: value
  })
}

export const logCardClick = (contentCardId) => {
  return Braze.logContentCardClicked({ contentCardId })
}

export const logContentCardImpressions = (contentCardId) => {
  return Braze.logContentCardImpression({ contentCardId})
}

export const requestContentCardsRefresh = () => {
  return Braze.requestContentCardsRefresh()
}

export const getCachedContentCards = async () => {
  const contentCards = await Braze.getCachedContentCards()
  if (contentCards.cards.length >= 1) {
    useContentCardsStore().setContentCards(
      contentCards.cards
    )
  }
}

export const getFeatureFlag = async (id: string): Promise<FeatureFlag | null | undefined> => {
  return await Braze.getFeatureFlag({ featureFlagId: id })
}

export const getAllFeatureFlags = async (): Promise<{ featureFlags: readonly FeatureFlag[] }> => {
  return { featureFlags: (await Braze.getAllFeatureFlags()).featureFlags }
}

export const logFeatureFlagImpression = async (id: string): Promise<{ result: boolean | undefined }> => {
  return await Braze.logFeatureFlagImpression({ featureFlagId: id })
}

export const refreshFeatureFlags = (onSuccess?: () => void, onError?: () => void) => {
  return Braze.refreshFeatureFlags(
    onSuccess,
    onError
  )
}

export const startLiveActivity = (payload: LiveActivityOptions) => {
  return Braze.startLiveActivity(payload)
}

const handleType = (type, inAppMessage) => {
  const handlers = {
    upsell: () => handleUpsell(inAppMessage),
    applyVoucher: () => handleApplyVoucher(inAppMessage),
    contactless: () => handleContactless(inAppMessage),
    oldAndroidApp: () => ({ display: isOldAndroidApp() }),
    isOldiOSApp: () => ({ display: isOldiOSApp() }),
    websitePurchase: () => handleWebsitePurchase(inAppMessage),
    default: () => ({ display: false })
  }

  const fn = handlers[type] || handlers.default
  return fn()
}

const handleUpsell = (inAppMessage) => {
  // Get Upsell Type
  const upsell = inAppMessage.extras.upsell

  // Get Upsell properties
  const toArray = str => str?.replace(/\s/g, '')?.split(',')
  const options = {
    addProductIds: toArray(inAppMessage.extras.addProductIds),
    removeProductIds: toArray(inAppMessage.extras.removeProductIds),
    productIds: toArray(inAppMessage.extras.productIds),
    buttonText: inAppMessage.extras.buttonText,
    imageName: inAppMessage.extras.imageName,
    imageUrl: replaceImageDomain(inAppMessage.extras.imageUrl),
    mobileImage: replaceImageDomain(inAppMessage.extras.mobileImage),
    description: inAppMessage.extras.description,
    redirectLink: inAppMessage.extras.redirectLink,
    inAppMessage: cloneDeep(inAppMessage)
  }

  // Display corresponding upsell
  const upsellHandler = {
    ingredientUpsell: () => UpsellController.displayIngredientUpsell(options),
    productUpsell: () => UpsellController.displayProductUpsell(options),
    posterUpsell: () => UpsellController.displayPosterUpsell(options),
    default: () => {}
  }
  const upsellFn = upsellHandler[upsell] || upsellHandler.default

  try {
    upsellFn()
  } catch (error) {
    return { display: false }
  }
}

const handleApplyVoucher = async (inAppMessage) => {
  try {
    const voucherCode = inAppMessage.extras.voucherCode
    if (voucherCode) {
      await BasketController.applyVoucher({ voucherCode, payload: { customerId: '' } })
      // Display if display=true was passed through braze
      return { display: inAppMessage.extras.display?.toLowerCase() === 'true' }
    }
  } catch (err) {
    // Don't display if applyVoucher failed
    console.error(err)
    return { display: false }
  }
}

const handleContactless = async (inAppMessage) => {
  try {
    const stores = inAppMessage.extras.stores
      .split(',')
      .filter(x => !!(x))
      .map(x => x.trim())

    const currStore = useCartStore().storeInfo?.storeId
    const display = currStore && stores.includes(currStore.toString())
    return { display }
  } catch (err) {
    console.error(err)
    return { display: false }
  }
}

const handleWebsitePurchase = (inAppMessage) => {
  try {
    if (!inAppMessage.extras?.campaignType) {
      return { display: false }
    }
    // Set the campaign data
    useCampaignStore().setCampaignExtras(inAppMessage.extras)
    // Show the popup
    useCampaignStore().setCampaignModal(true)
    return { display: true }
  } catch (err) {
    return { display: false }
  }
}

export const handleBrazeUpsellClick = (upsell, btnId) => {
  if (upsell?.popupName === 'BrazePopup' && upsell?.inAppMessage) {
    // Clone deep as logInAppMessageButtonClick modifies inAppMessage causing pinia mutated state
    const inAppMessage = cloneDeep(upsell.inAppMessage)
    handleInAppMessageClick(inAppMessage, btnId)
  }
}

const handleInAppMessageClick = (inAppMessage, buttonId) => {
  Braze.logInAppMessageButtonClicked({
    inAppMessage,
    buttonId
  })
  Braze.requestImmediateDataFlush()
}
