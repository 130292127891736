import  type { paths, components  } from '@/services/open-api/generated/survey'
import { fetchClient } from '@/services/open-api/fetchClient'

export default {
  /**
   * Retrieves a survey by its ID.
   * @param apiRoot The base URL of the API.
   * @param surveyId The ID of the survey to retrieve.
   * @param orderId The order ID associated with the survey.
   * @returns The survey.
   */
  async GetSurvey ({ apiRoot, surveyId, orderId } : {
    apiRoot: string
    surveyId: string
    orderId: string
  }) {
     return await fetchClient<paths>({
      baseUrl: apiRoot
    }).GET('/api/v1/survey/surveys/{surveyId}', {
      params: {
        path: { surveyId },
        query: { orderId }
      }
    })
  },

  /**
   * Submits a response to a survey question.
   * @param apiRoot The base URL of the API.
   * @param surveyId The ID of the survey to submit the response to.
   * @param questionId The ID of the question to submit the response to.
   * @param payload The response to submit.
   * @returns The response from the API.
   */
  async SendResponse ({ apiRoot, surveyId, questionId, payload } : {
    apiRoot: string
    surveyId: string
    questionId: string,
    payload: components['schemas']['Answer']
  }) {
    return await fetchClient<paths>({
      baseUrl: apiRoot
    }).POST('/api/v1/survey/surveys/{surveyId}/questions/{questionId}/response', {
      params: {
        path: {
          surveyId,
          questionId
        }
      },
      body: {
        ...payload
      }
    })
  }
}
