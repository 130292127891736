import  type { paths, components  } from '@/services/open-api/generated/user'
import { fetchClient } from '@/services/open-api/fetchClient'

export default {
  /**
   * Marks the user with the given `userId` as opted out from email subscriptions.
   * @param apiRoot The base URL of the API.
   * @param userId The ID of the user to opt out.
   * @returns A promise resolving to the response of the API.
   */
  async EmailOptOut ({ apiRoot, userId } : {
    apiRoot: string
    userId: string
    payload: components['schemas']['Serverless.User.Api.Models.Dto.EmailOptin']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/user/{userId}/optin/email', {
      params: {
        path: {
          userId
        }
      },
      body: {
        optinEmail: false
      }
    })
  },

  /**
   * Marks the user with the given `email` as opted out from email subscriptions.
   * @param apiRoot The base URL of the API.
   * @param payload The email address of the user to opt out.
   * @returns A promise resolving to the response of the API.
   */
  async EmailOptOutByEmail ({ apiRoot, payload } : {
    apiRoot: string
    payload: components['schemas']['Serverless.User.Api.Models.Dto.EmailOptout']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/user/optout/email', {
      body: {
        ...payload
      }
    })
  },

  /**
   * Marks the user with the given `shortUserId` as opted out from SMS subscriptions.
   * @param apiRoot The base URL of the API.
   * @param shortUserId The short user ID of the user to opt out.
   * @returns A promise resolving to the response of the API.
   */
  async SMSOptOutShort ({ apiRoot, shortUserId } : {
    apiRoot: string
    shortUserId: string
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/user/shortuserid/{shortUserId}/optin/sms', {
      params: {
        path: {
          shortUserId
        }
      },
      body: {
        optinSms: false
      }
    })
  },

  // SupercarsFanOptOutShort ({ apiRoot, shortUserId }) {
  //   return axios.post(`${apiRoot}/api/v1/user/shortUserId/${shortUserId}/optin/supercars`, { optinSupercarsFan: false })
  // },

  /**
   * Marks the user with the given `shortUserId` as opted out from Supercars Fan subscriptions.
   * @param apiRoot The base URL of the API.
   * @param shortUserId The short user ID of the user to opt out.
   * @returns A promise resolving to the response of the API.
   */
  async SupercarsFanOptOutShort ({ apiRoot, shortUserId } : {
    apiRoot: string
    shortUserId: string
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/user/shortuserid/{shortUserId}/optin/supercars', {
      params: {
        path: {
          shortUserId
        }
      },
      body: {
        optinSupercarsFan: false
      }
    })
  },

  /**
   * Retrieves a user by their short user ID.
   * @param apiRoot The base URL of the API.
   * @param shortUserId The short user ID of the user to retrieve.
   * @returns A promise resolving to the response of the API.
   */
  async GetByShortId ({ apiRoot, shortUserId } : {
    apiRoot: string
    shortUserId: string
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).GET('/api/v1/user/shortUserId/{shortId}', {
      params: {
        path: {
          shortId: shortUserId
        }
      },
    })
  },

  /**
   * Retrieves a user entry by their short user ID, user ID, or email address.
   * @param apiRoot The base URL of the API.
   * @param payload The request body containing the short user ID, user ID, or email address to retrieve the user entry for.
   * @returns The user entry.
   */
  async GetEntry ({ apiRoot, payload } : {
    apiRoot: string
    payload: components['schemas']['Serverless.User.Api.Models.Dto.EntryRequest']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/user/getEntry', {
      body: {
        ...payload
      }
    })
  },

  async SMSOptOutMobileNumber ({ apiRoot, payload } : {
    apiRoot: string
    payload: components['schemas']['Serverless.User.Api.Models.Dto.SmsOptout']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/user/optout/sms', {
      body: {
        ...payload
      }
    })
  },

  async CampaignForm ({ apiRoot, payload } : {
    apiRoot: string
    payload: components['schemas']['Serverless.User.Api.Models.Dto.UserEvent']
  }) {
    return await fetchClient<paths>({ baseUrl: apiRoot }).POST('/api/v1/user/campaign', {
      body: {
        ...payload
      }
    })
  }
}